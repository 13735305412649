
.online-course-modal .MuiInputBase-input{
    width: 350px !important;
    margin-bottom: 10px !important;
}
.online-course-modal .MuiOutlinedInput-multiline textarea{
    height: 120px !important;
}
.modal-online-title h6{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.modal-online-title{
    padding: 16px 13px !important;
}
.modal-online-title h3{
    font-weight: normal;
    color: #123B85;
}

.modal-online-title h6>div{
    width: 45%;
}
.terms-and-conditions p{
    line-height: 25px;
    font-size: 15px;
}
.terms-and-conditions>p:first-child{
    margin-bottom: 40px;
}
.terms-and-conditions>p:first-child span{
    color: #05912F;
}
.terms-and-conditions>p:nth-child(3){
    margin:15px 0 50px
}
.terms-and-conditions h4,
.terms-and-conditions>p:nth-child(4){
    border-left: 1px solid #123B85;
    padding-left: 7px;
}

.terms-and-conditions h4{
    color: #123B85;
}
.border{
    border-right: 3px solid #123B85;
}

.information{
    color: red;
    padding-top: 40px;
    font-style: italic;
}
.info-link{
    font-style: italic;
    font-size: 13px;
    margin-top: 7px;
}
.info-link span {
    cursor: pointer;
    color:#123B85 ;
}
.lesson-type-title{
    font-size: 14px;
}
.price-for-courses{
    border-top: 1px solid #123B85;
    margin-top: 12px;
    padding-top: 7px;
    font-size: 15px;
}
.price-for-courses span{
    color: #123B85;
}
@media(min-width:320px) and (max-width:465px){
    .online-course-modal .MuiInputBase-input{
        width: 200px !important;
        margin-bottom: 10px !important;
    }
}
@media (min-width:320px) and (max-width:870px){
    .terms-and-conditions{
        width: 100% !important;
    }
    .border{
        display: none;
    }
    .modal-online-title h3{
        font-size: 16px;
    }
    .online-course-modal .MuiOutlinedInput-multiline textarea{
        height: 40px !important;
    }
    .terms-and-conditions>p:first-child,
    .terms-and-conditions>p:nth-child(4){
        display: none;
    }
    .online-course-modal .MuiInputBase-input{
        height: 5px !important;
    }
}