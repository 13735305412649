body {
  background-color: #f8f8f8;
}
.blog_background_image{
  background-image: url("/images/img8.jpg");
}
.blog {
  background-color: white;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-bottom: 100px;
  padding-top: 85px;
  position: relative;
  z-index: 2;
  height: 470px;
}
.blog::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #1a304e;
  opacity: 0.9;
  z-index: -1;
}

.blog-parent {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
  width: 80%;
  margin: 67px auto 0;
}
.cardul {
  width: 24%;
  height: 320px;
  border-radius: 15px;
  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  z-index: 5;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}
.blog-loading{
  position: absolute;
  left: 0;
  top: 0;
}
.cardul:hover {
  transform: translateY(20px);
}

.cardul:hover:before {
  opacity: 1;
}

.cardul:hover .info {
  opacity: 1;
  transform: translateY(0px);
}
.cardul:hover h2 {
  background-color: transparent;
  z-index: 3;
  color: white;
}
.cardul:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.cardul img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.cardul .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  margin: -24px auto;
  transition: 0.5s;
}

.cardul .info h1 {
  margin: 0px;
}

.cardul .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}
.cardul h2 {
  position: absolute;
  bottom: 60px;
  background: rgba(255,255,255,0.7);
  width: 100%;
  left: 0;
  height: 59px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 19px;
  color: black;

}
.blog-parent > div div a {
  padding: 8px 8px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 5%;
  text-transform: capitalize;
  border-bottom: 2px solid #00ffd6;
}
.blog-parent > div div a:hover {
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
}
.blog-parent > div div a::before {
  transform: translate(-50.999999%, -49.99%) rotate(-37deg);
}
.blog h5 {
  background-color: rgba(255, 255, 255, 0.1);
  width: 15%;
  margin: 0 auto 10px;
  font-size: 17px;
  border-bottom: 2px solid#00ffd6;
  padding: 0.8em 0.1em;
  border-radius: 3px;
  letter-spacing: 1px;
  color: white;
}
.big-blog>h2 {
  color: white;
  font-size: 35px;
  margin-top: 27px;
}

@media (min-width: 320px) and (max-width: 768px) {
  .cardul {
    width: 320px;
    margin-bottom: 30px;
  }
  .blog h2 {
    font-size: 24px;
    color: black;
  }
  .blog h5 {
    width: 280px;
    color: black;
  }
  .blog-parent > div div a {
    font-size: 18px;
  }
  .cardul h2 {
    font-size: 18px;
    bottom: 75px;
  }
  .blog {
    background-image: none;
    height: auto;
    padding-bottom: 0px;
    background-color: #ebebeb;
  }
  .blog::after {
    display: none;
  }
  .blog-parent {
    margin: 42px auto 0;
  }
}

@media (min-width: 769px) and (max-width: 1020px) {
  .cardul {
    width: 300px;
    margin-bottom: 30px;
  }
  .cardul h2 {
    font-size: 20px;
    bottom: 95px;
  }
  .blog-parent > div div a {
    font-size: 18px;
  }
  .blog h5 {
    width: 280px;
    color: black;
  }
  .blog h2 {
    color: black;
  }
  .blog {
    background-image: none;
    height: auto;
    padding-bottom: 27px;
    background-color: #ebebeb;
  }
  .blog::after {
    display: none;
  }
  .blog-parent {
    margin: 42px auto 0;
  }
}

@media (min-width: 1020px) and (max-width: 1750px) {
  .blog-parent {
    width: 97%;
  }
  .cardul h2 {
    font-size: 20px;
    height: 65px;
    bottom: 85px;
  }
  .blog h5 {
    width: 280px;
  }
  .blog-parent > div div a {
    font-size: 20px;
  }
}

@media (min-width: 1601px) {
  .blog-parent {
    width: 80%;
  }
}
