.blog-header{
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(111, 120 ,136 ,0.6);
    width: 100%;
    z-index: 1000;
}
.blog-header-parent{
    display:flex;
    align-items: center;
    padding:10px 0;
    width: 61%;
    justify-content: space-between;
    margin: 0 auto;
}
.blog-header img{
    width: 96%;
}
.blog-header button{
    padding:11px 70px;
    background-color: transparent;
    border:1px solid white;
    border-bottom:2px solid #00FFD5;
    color:white;
    font-size: 20px;
    letter-spacing: 2px;
    border-radius: 4px;
    margin: 0;
    cursor: pointer;
}


@media (min-width:320px) and (max-width:768px){
    .blog-header-parent{
        width: 82%;
    }
    .blog-header button{
        padding:5px 30px;
    }
    .blog-header img{
        width: 80%;
    }
}


