.slider {
	position: relative;
	width: 100%;
	height: 85vh;
	overflow: hidden;
	color:black;
}

.slider::after{
	content: "";
	background-color: #1A304E;
	opacity: 0.4;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.slide {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden
}


.slider>div{
	height: 100%;
	width: 100%;
	display: flex;

}
.slider>div>div{
	width: 100%;
}
.slider>div:first-child{
	text-align: center;
	color: white;
    text-align: left;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
	background: rgba(0,0,0,0.2);
}
.slider>div>div:last-child{
	color: white;
	text-align: left;
	display: flex;
    justify-content:flex-start;
	align-items: center;
	width: 63%;
    margin: 0 auto ;
	
}
.slider h1{
	margin-top: 15px;
	font-size: 38px;
	margin-bottom: 10px;
}
.slider p{
	font-size: 20px;
}

.slider polygon{
	fill:white
}
.slider a.nextButton,.slider a.previousButton{
	right: 0 !important;
}

.subtitle{
	font-size: 50px;
	color:#00FFD6;
	border-left: 2px solid #00FFD6;
	padding-left: 15px;

}


@media(max-width:600px){
	.slider {	
		width: 100%;
		height: 65vh;

	}
	.slider>div{
		width: 100%;
		margin: 0;
	}

	.slider>div>div:last-child{
		width: 85%;
		margin-left: 15px;
	}
	.slider svg{
		width: 15px;
	}

	.slider>div{
		height: 100%;
	}
	.slider h1{
		font-size: 27px;
	}
	.slider p{
		font-size: 15px;
		display: inline-block;
	}
	.subtitle{
		font-size: 23px;
	}
	
	.time-wrapper {
		font-size: 17px;
	}


}

@media(min-width:601px) and (max-width:992px){
	

	.subtitle{
		font-size: 30px;
	}
	.slide h1{
		font-size: 42px;
	}

	
	.slider>div>div:last-child {
	    width: 83%;
		color: white;
		text-align: left;
		
	}
}


@media(min-width:992px) and (max-width:1200px){

	.subtitle{
		font-size: 55px;
	}

	.slide>div>div:last-child {

		color: white;
		text-align: left;
	}
}

@media(min-width:1200px) and (max-width:1800px){

	
.slide>div>div:last-child {
    color: white;
    text-align: left;
}
}