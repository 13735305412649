.about-us{
    width: 100%;
    margin-top: 223px;
}
.video-react-control-bar,
.video-react-control-text
{
  display: none;
}

.about-us-parent{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px; 
}

.about-us-parent-left{
    display:flex;
    justify-content: flex-end;
    width: 43%;
  
}
.about-us-parent-left img{
    width: 100%;
    height: 346px;
    box-shadow: 0px 0px 0.8em #123B85;

}
.about-us-parent-left>div{
  width:100% !important;
  height: 100% !important;
  position: relative;
  top:0;
  left: 273px;
  padding-top: 0 !important;
}
.imp{
    position: absolute;
    z-index: 100;
    background: white;
    width: 656px;
    height: 305px;
    left: 49.4%;
    padding: 41px;
}
.imp p{
  line-height: 30px;
  color:#123B84;
  font-style: italic;
}
.imp span{
  color:#0a3886;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
}
.about-us-parent-left>div video{
  width: 100%;
  height: 100%;
}
.fg{
  background:#EBEBEB;
  margin-top: 20px;
  padding:20px 0 20px 10px
}
.fg p{
  display:flex;
  
}
.fg img{
  width: 39px;
  margin-right: 10px;
}
.about-us-parent-left>div::after{
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  width: 100%;
  height: 95%;
  background: #04296b;
  opacity: 0.7;
}

.about-us-parent-right{
  margin-top: 12px;
  padding-top:80px;
  width: 790px;
  height: 333px;
}
.about-us-parent-right>div>p,
.about-us-parent-right>div>div>p{
  font-size: 18px !important;
  color:#123B84
}

.who-we-are{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  flex-wrap: wrap;
}
.who-we-are>div:first-child{
  width: 42%;
}

.who-we-are-more h3{
  color: #123B84;
  font-size: 23px;
  border-left: 2px solid#00ffd6;
  border-right: 2px solid #00ffd6;
  width: 17%;
  height: 26px;
  text-align: center;
  
}

.who-we-are-more>p{
  width: 94%;
  font-size: 20px;
  margin-top: 15px;
  line-height: 36px;
  font-style: italic;
  color: #123B84;
 
}
.certeficate{
  width: 563px;
  height: 405px;
  background:white;
  border:2px solid #103475;
  box-shadow: inset 0px 0px 0.5em #103475;
}
.about-us-text{
  width: 97%;
  padding: 10px 0px;
  margin-top: 15px;
  margin-bottom: 32.5px;
  color:#123B84;
  line-height: 29px

}
.certeficate img{
  width: 100%;
  transform: translate(-36px,-35px);
}
.about-us-text p{
  display: flex;
}
.about-us-text img{
  width: 38px;
  margin-right: 20px;
}
.bordered{
  width: 73%;
  border-top:3px solid #123B84;
  margin-bottom: 40px;
}
.who-we-are-more>div>p{
  font-size: 18px !important;
  font-style: italic;
  color:#123B84;
}
@media(min-width:320px) and (max-width:992px){
  .who-we-are>div:first-child,
  .who-we-are-more>p,
  .who-we-are-more>p,
  .about-us-text
 
  {
    width: 100% !important;
    margin: 20px 0;
  }
 
  .about-us-parent-left{
  width: 94% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  }


  .certeficate {
    width: 92%;
    left: 0;
    padding: 0;
    height: 0%;
    background-color: transparent;
    text-align: center;
    border: none;
    box-shadow: none;
}
 .imp{
  left: 4.5%;
  padding: 10px 0 30px;
  height: auto;
  width: 94%;
 
 }
 .fg{
  background: #EBEBEB;
  margin-top: 20px;
  width: 95%;
  padding: 20px 0 20px 10px;
  margin: 10px auto;
}
 .about-us-parent-left>div{
   left: 1.5%;
 }
 .about-us-parent-right {
   padding-left: 0;
   width: 97%;
   margin: 0 auto;
   padding-top: 0;
  
 }
 .who-we-are-more>p{
   font-size: 16px;
   padding-left: 10px;
 }
 .about-us{
   margin: 0;
 }
 .bordered{
   margin: 15px auto 30px;
   display: none;
 }
 .certeficate img {
  transform: translate(0px,0px);
 }
 .who-we-are-more h3{
   width: 146px;
   height: auto;
 }
 .who-we-are{
  padding: 0 9px;
 }
 .about-us-parent-right>div>p{
   font-size: 14px !important;
   padding: 0 12px;
 }
 .who-we-are-more>div>p{
  font-size: 15px !important;
 }
 .about-us-parent-left>div::after{
  height: 94%;
}
}
@media (min-width:320px) and (max-width:400px){
  .blog-parent > div div a{
    font-size: 16px !important;
    padding: 6px 6px !important; 
  }
  .about-us-parent-right{
    height:418px;
  }
  .about-us-parent-right>div>div>p{
    font-size: 15px !important;
  }
}
@media (min-width:320px) and (max-width:530px){
  .about-us-parent{
    padding-bottom: 60px;
  }
}
@media (min-width:460px) and (max-width:550px){
  .about-us-parent-left>div::after{
    height: 92%;
  }
}
 @media(min-width:769px) and (max-width:992px){
  .about-us-parent-left{
    width: 77% !important;
    margin: 0 auto;
  }
  .about-us-parent-right{
    height: 285px;
  }
  .imp{
    width: 80% !important;
    left: 11.1%;
    height: auto;

  }
  .certeficate {
    width: 65%;
    left: 0;
    padding: 10px 0;
    height: 14%;
}
.certeficate img {
  transform: translate(0px,0px);
}
  .who-we-are-more>p{
    width: 93%;
  }
  .fg{
    background: #EBEBEB;
    margin-top: 20px;
    width: 87%;
    padding: 20px 0 20px 10px;
    margin: 10px auto;
  }
} 


@media(min-width:993px) and (max-width:1400px){

  .who-we-are>div:first-child {
    width: 59%;
    margin-top: 0;
  }
  .about-us-text{
    width: 86%;
    margin-top: 50px;
  }
  .about-us-parent-right{
    width: 40%;
    padding-top: 0;
  }
  .about-us-parent-left>div{
    left: 7.8%;
  }
  .imp{
    width: 475px;
    padding:15px;
    height: 320px;
  }
  .fg{
    background: #EBEBEB;
    margin-top: 20px;
    width: 87%;
    padding: 20px 0 20px 10px;
    margin: 10px auto;
  }
  .who-we-are-more h3{
    padding-left: 10px;
    width: 170px;
    height: auto;
  }
  .about-us {
    margin-top: 142px;
  }
  .who-we-are-more>p {
    font-size: 17px;
  }
  .certeficate {
    width: 39%;
    height: 7%;
  }
  .who-we-are>div:first-child{
    width: 54%;
  }
}


@media(min-width:1401px) and (max-width:1660px){
  .imp{
    margin-top: -57px;

  }
  .who-we-are-more h3{
    padding-left: 10px;
    width: 170px;
    height: auto;
  }
  .about-us-parent-left>div {
    left: 20%;
  }
  .who-we-are-more>p{
    font-size: 19px;
  }
  .certeficate {
    width: 34%;
    height: 7%;
  }
  .who-we-are>div:first-child{
    width: 47%;
  }
  .about-us-text{
    width: 92%;
  }
}

@media(min-width:1627px) and (max-width:1836px){
  .who-we-are-more h3{
    padding-left: 10px;
    width: 170px;
    height: auto;
  }
  .about-us-parent-left>div {
    left: 34%;
  }
 .about-us-text{
    width: 93%;
  }
  .who-we-are-more>p{
    font-size: 18px;
  }
}



