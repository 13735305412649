.more-about-blog-background{
    background-image: url("/images/blog-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;
    height: 600px;
    margin-top: 0px;
    position: relative;
}
.more-about-blog-background::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #233d63;
    opacity: 0.9;
}
.blog-text{
    position: relative;
    z-index:2;
    display:flex;
    flex-direction: column;
    align-items: center;
    top: calc(600px - 64%);
    color:white
}

.ShareButtonBlog{
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px rgba(0,0,0,1); 
    margin-bottom: 5px;
    margin-top: 15px;
}


.blog-text>h3{
    width: auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    color: white;
    padding:8px;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 15px;
}
.blog-text>h2{
    font-size: 35px;
}
.blog-images-and-text{
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    width: 48%;
    margin:40px auto 0;
   
}
.blog-images-and-text>div:first-child{
    width: 41%;
}
.blog-images-and-text>div:last-child{
    width: 47%;
}
.blog-img-parent{
    height: 292px;
    margin-bottom: 10px;
    overflow: hidden;
}
.more-about-blog-top{
     border-bottom:2px solid  ;
     border-image: linear-gradient(to right, #4458dc 17%, #00FFD5 99%) 1;
     margin-bottom: 30px;
     padding-bottom: 20px;
}
.blog-title h2{
    text-shadow: 0 0 0.6em #123C86;
    margin-bottom: 15px;
    font-size: 32px;
}
.blog-info{
    width: 60%;
    margin: 0 auto;
    line-height: 30px;
}
.more-img{
    width: 378px;
}
.more-about-blog-bottom{
    padding-bottom: 20px;
    margin-bottom: 10px;
    padding-top: 20px;
    border-bottom:2px solid;
    border-image: linear-gradient(to right, #4458dc 17%, #00FFD5 99%) 1;
}

.more-about-full{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
    margin:0 auto;
    
}
.more-about-full>div{
    margin-bottom: 30px;
    padding-bottom: 15px;
    border:0.5px solid lightgray;
    text-align: center;
    box-shadow: 0 0 0.5em #123C86;
    height: 427px;
    width: 389px;
    transition: 0.5s;
    
}
.more-info-blog{
    padding-left: 20px;
    text-align: left;
    margin-top: 7px;
}
.more-info-blog h3{
    font-size: 21px;
    margin: 0 0 7px;
    text-transform: capitalize;
    text-shadow: 0 0 0.5em #123C86;
}
.more-info-blog p{
    font-style: italic;
    margin: 4px 0;
}
.more-info-blog a{
    font-size: 18px;
    color:#123C86;
    cursor: pointer;
    transition: color 0.5s linear;
}
.more-info-blog a:hover{
    color: #04d3c3;
}
.more-about-blog-bottom img{
    width: 100%;

}
.more-about-full span{
    display: block;
    margin-bottom: 5px;
}
.arrow-down{
    width: 50px;
}
.view-more{
    display: flex;
    justify-content: center;  
}
.view-more button{
    width: 200px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 50px;
    align-items: center;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    border-radius: 50px;
    transition: all .4s ease-in-out;
}


.view-more button:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.view-more button:focus {
    outline: none;
}
.view-more .eye{
    margin-left: 20px;
    width: 20px;
    height: 20px;
}
.more-about-blog-background svg{
    position: absolute;
    z-index: 3;
    bottom: 0px;
    fill: #f8f8f8;
}


.more-about-full>div:hover{
    box-shadow: 0 0 1.8em #123C86;
}

.blog-img-loading{
    margin-bottom: 0  !important;
}
@media (min-width:320px) and (max-width:1200px){
    .more-about-blog-background{
        height: 555px;
    }

    .more-about-blog-bottom img {
        height: 281px;
        width: 100%;
    }
    .more-about-full>div {
        height: 384px;
        width: 310px;
    }
    .more-info-blog h3{
        font-size: 17px;
    }
    .blog-title h2{
        font-size: 22px;
    }
    .blog-text>h2 {
        font-size: 27px;
        text-align: center;
    }
    .more-img{
        width: 300px;
    }
    .blog-info{
        width: 90%;
    }
    .more-about-full {
        width: 94%;
        justify-content: center;
    }
    .blog-images-and-text{
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        width: 94%;

    }
    .blog-images-and-text>div:first-child,
    .blog-images-and-text>div:last-child{
        width: 100%;
    }
    .blog-images-and-text>div:last-child{
        margin-top: 20px;
    }
    .blog-img-parent{
        height: 275px;
    }
   
}

@media (min-width:660px) and (max-width:1200px){
    .more-about-full{
        justify-content: space-around;
    }
    
}

@media (min-width:1201px) and (max-width:1480px){
    .blog-images-and-text{
        width: 900px;
    }
    .more-about-full {
        width: 96%;
        justify-content: space-around;
    }
    .blog-images-and-text{
        width: 900px;
    }
    .blog-images-and-text>div:first-child{
        width: 41%;
    }
    .blog-images-and-text>div:last-child{
        width: 47%;
    }
}

@media(min-width:1481px){
    .blog-images-and-text{
        width: 900px;
    }
}
