
.all-information-about-courses{
    background:#022440;

}
.all-information-about-courses .carder{
    width: 280px;
}
.banner{
    background: #f8feff;
    padding:140px 0 40px;
    border-bottom-left-radius: 53px;
    border-bottom-right-radius: 53px;
    width: 90%;
    margin:0px auto ;
}

.all-information-about-courses .carder {
    margin-right: 15px;
}


.ShareButton{
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px rgba(0,0,0,1); 
    margin-bottom: 5px;
}

.ShareButtonText{
     font-size: 20px;
     color: "#123b85"; 
     padding: 4px 
}

.ShareButtonBox{
    justify-content:flex-end !important
}

.course_image{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}
.course_image > button{
    width: 70%;
}
.banner>div{
    display: flex;
    justify-content: space-between;
    width: 76.8%;
    margin: 0 auto;

}
.course-title{
    text-align: left;
    color:white;
    width: 100%;
    
}
.course-title h2{
    font-size: 40px;
    color:#123B85
}
.course-title h2>span{
    color:#00FFD5
}
.course-title div.conditions{
    width: 90%;
    font-size: 18px;
    margin:15px 0 30px;
    color: black;
    line-height: 31px;
}

h4.term_item::before {
    content: '\2713';
    color: #0071bb;
    margin-right: 10px;
}
.term h3{
    width: 90%;
    font-size: 18px;
    margin:15px 0 0;
    color: black;
    line-height: 30px;
}
.term>div{
    width: 48%;
}

.term_item{
    width: 100%;
    margin:10px 0 0;
    color: black;
    line-height: 24px;
}
button.register_course{
    padding:12px 0px;
    font-size: 20px;
    background:transparent;
    border: 1px solid #123b85;
    border-bottom:2px solid #00FFD5;
    color: #123B85;
    letter-spacing: 3px;
    /* font-weight: bold; */
    border-radius: 4px;
    display: block;
    margin: 20px auto;
    cursor: pointer;
}
button.register_course:hover{
    color: white;
    border: 1px solid transparent;
    border-bottom:2px solid transparent;
}
button.register_course.btn4.btns{
    z-index: 2;
}
.general-image{
    height: 402px;
    width: 319px;
    border-radius: 20px;

}
.term{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding:20px 0;
    border-bottom-left-radius: 53px;
    border-bottom: 1px solid white;
    border-bottom-right-radius: 53px;
}
.global{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.type-of-courses{
    border-right: 2px solid #00ffd6;
    margin-right: 15px;
    padding-right: 15px;
}

.global p{
    color: black;
    margin:10px 0 0;
    line-height: 24px; 
    font-size: 17px;
}

.term span:last-child{
    color:#0a3886;
}
.term span{
    font-weight: bold;
}
.more-informacion{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 60%;
    margin:50px auto 0
}
.course-description{
    display: flex;
    flex-direction: column;
}
.course-description h3{
    color: white;
    width: 90%;
    font-size: 30px;
    text-align: center;
}
.languages-to-learn>h3{
   font-size: 22px;
   color:#123b85;
   margin-top: 19px;
}
.icons-for-lang{
    height: 100px;
}
.course-language-name{
    color: black;
    margin-top:5px
}
.image-parent-icon{
    width: 100%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.image-parent-icon div{
    text-align: center;
    color:white;
    padding: 12px 8px;
    border-radius: 3px;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.more-informacion p{
    width: 95%;
    padding:30px 0px 20px;
    line-height: 36px;
    color:white;
    font-size: 19px;
    font-style: italic;
}
.more-informacion img{
    height: 80px;
}

.workers {
    border-top: 1px solid #0a3886;
    margin-top: 50px;
    border-bottom: 1px solid #0a3886;
}

.workers-parent>div:first-child>h3{
    color:#123B85;
    font-size: 22px;
    margin-bottom: 30px;
    margin-top:30px
}
.courses-border{
    border-top: 1px solid #0a3886;
    width: 80%;
}
.workers-parent{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
.tutor{
    height: 310px;
    width: 291px;
    margin-bottom: 45px;
    margin-right: 20px;
}

.notes img{
    width: 30px;
    margin-right: 10px;
}
.notes-parent{
    color:#123B85;
    width: 50%;
}
.notes-parent h3{
    text-align: left;
    font-size: 22px;
    margin-top: 35px;
    margin-bottom: 23px;

}
.notes p{
    font-size: 18px;
    color: black;
    text-align: left;
    line-height: 30px;
}
.notes{
    width: 100%;
}
.notes>div{
    margin:25px auto 25px;
    display:flex;
    flex-direction: column;
    line-height: 45px;
}
.notes>div>div{
    margin-bottom: 9px;
}
.notes div{
    display:flex;
    align-items: flex-start;
}
.tutor-image{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}



@media (min-width:320px) and (max-width:800px){
    .banner{
        padding: 100px 0 60px;
    }
    .course-title h2{
        font-size: 30px;
    }
    .languages-to-learn>h3{
        font-size: 24px;
        margin-top: 7px;
    }
    .header-parent>div:first-child{
        width: 54%;
    }
    .term{
        padding: 0;
        flex-wrap: wrap;
    }
    .banner>div{
         width: 82.8%;
         flex-wrap: wrap;
    }
    .term>div{
        width: 100%;
        margin-bottom: 15px;
    }
    .term>div:last-child{
        width: 100%;
    }
    .general-image{
        height: 293px;
        width: 225px;
    }
    button.register_course{
        font-size: 18px;
    }
    .all-information-about-courses .carder{
        width: 237px;
        height: auto;
    }
    .all-information-about-courses .carder p{
        font-size: 12px;
    }
    .notes-parent ,
    .course-title div.conditions {
        width: 100%;
    }
    .course-title div.conditions {
        font-size: 16px;
    }
 
   
    .all-information-about-courses .details{
        width: 100%;
    }
    .workers-parent>div:first-child>h3,
    .notes-parent h3,
    .course-description h3{
        font-size: 26px;
    }
    .course-description h3{
        width: 95%;
    }
    .notes p{
        font-size: 16px;
    }
    .notes img{
        width: 26px;
    }
    .image-parent-icon{
        margin-top: 10px;
        justify-content: space-around;
    }
    .more-informacion p{
        font-size: 16px;
        width: 100%;
    }
    .more-informacion{
        width: 80%;
    }
}
@media (min-width:801px) and (max-width:1510px){
    .workers-parent{
        justify-content: center;
    }
    .notes-parent{
        width: 69%;
    }
    .tutor-image{
        justify-content: center;
    }
    .tutor-img-loading{
        margin-right: 100px;
        width:320px !important;
        height: 350px !important;
    }
    .notes-loading{
        width: 500px !important;
    }
   
}
@media (min-width:801px) and (max-width:1740px){
    .banner{
        width: 93%;
    }
    .general-image{
        height: 389px;
    width: 300px;
    }
    .banner>div {
        width: 85.8%;
    }
    .term{
        flex-wrap: wrap;
    }
    .term>div,
    .term>div:last-child {
        width: 98%;
    }
    .course_image {
        justify-content: flex-start;
    }
    .languages-to-learn>h3{
        margin-top: 10px;
    }
    .more-informacion{
        width: 80%;
    }
    .more-informacion p,
    .course-description h3{
        width: 100%;
    }

}


@media (min-width:1391px) and (max-width:1740px){
    .term>div{
        width: 43% !important;
    }
    .notes-loading{
        width: 600px !important;
    }

}
@media(min-width:1741px){
    .notes-loading{
        width: 700px !important;
    }

}
