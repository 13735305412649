

.work-form{
    width: 40%;
    margin: 0 auto;
    background-color: #d4e3ff;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;

}
.work-form .makeStyles-root-1 > *{
    width: 75ch !important;
    margin: 21px auto 20px !important;

}
.work-form .MuiFormGroup-row{
    padding: 10px;
    width: 100%;  

}

.work-form .MuiInput-root{
    width: 100% !important;
}

.work-form .Mui-required {
    margin-bottom: 15px; 
     padding-left: 6px;
}
.work-form .MuiFormLabel-root{
    margin-top: 5px;
    margin-bottom: 12px;
}
.knowledge-parent{
    display: flex;
    justify-content: space-between;
    width: 95.7%;
     background-color:#F8F9FA;
    padding:10px 15px ;
    align-items: center;
}
.radio-buttons{
    display: flex;
    width: 81%;
}
.radio-buttons>div{
    width: 98%;
    display: flex;
    justify-content: space-around;
}
.radio-buttons .MuiFormControlLabel-root{
    margin:0 !important
}

.answer-of-question{
    width: 74%;
    display: flex;
    justify-content: space-around;
}

.level-of-knowledge{
    padding-left: 10px !important;
}
.languages-level{
    display: flex;
    justify-content: space-between;
    background: #F8F9FA;
    padding: 10px 15px;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
}
.fieldests{
    width: 100%;
}
.languages-level .MuiFormControlLabel-root {
    width: 94%;
    display: flex;
    justify-content: space-between;
}
.languages-level .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px !important;
}

.work-form .MuiButton-containedPrimary{
    width: 200px !important;
    height: 40px !important;
    background: transparent;
    border: 2px solid black;
    border-bottom: 2px solid #123B85;
    color: black;
    transition: 0.7s;
}
.work-form .MuiButton-containedPrimary:hover{
    background-color:#123B85 ;
    border:none;
    color: white;
}
.job-application-parent{
    width: 100%;
    height: auto;
    background-color: #F6F6F6;
    padding-top: 20px;
}
.work-form>div{
    width: 77%;
    background-color: white;
    padding:10px 15px 20px;
    border:1px solid lightgray;
    border-radius: 6px;
}
.other-data .MuiFormLabel-root{
    margin-bottom: 12px;
    margin-left: 6px;
}
.work-form .MuiButtonBase-root {
    padding: 0 !important;
    margin-left:5px;
}
.work-form .PrivateSwitchBase-root-6{
    margin-top: 0 !important;
}

.cv .MuiFormLabel-root{
    margin-left: 10px;
}
.gender .MuiFormControlLabel-root,
.marital-status .MuiFormControlLabel-root{
    margin-bottom: 10px;
}
.gender .MuiIconButton-label,
.marital-status .MuiIconButton-label{
    margin-left: 10px;
}
.gender  .MuiTypography-body1,
.marital-status  .MuiTypography-body1{
    margin-left: 6px;
    margin-top: 2px;
}
.icon-for-job
{
    width:43%;
    margin:0 auto;
    padding:20px ;
    background-color: white;
    border-radius: 8px;
    border-bottom: 15px solid #123B85;
   
}
.img{
     display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.img div{
    width: 73%;
}

.icon-for-job img{
    width: 198px;
}
.icon-for-job p{
    width: 100%;
    line-height: 22px;
    
}
.icon-for-job p:last-child{
    margin-top: 15px;
}

.job-border p{
    margin-top: 43px;
    text-align: right;
    font-style: italic;
}
.work-form .MuiFormLabel-asterisk,
.error-for-files{
    color:red !important
}
.cv .MuiButton-contained{
    background-color: transparent !important;
    border:1px solid gray;
    padding:8px 15px !important
}
.cv .MuiButton-contained:hover{
    box-shadow: 0 0 0 transparent !important;
}
.cv .MuiIconButton-root:hover{
    background-color: transparent !important;
}
.level-of{
    display: flex;
    justify-content: space-around;
    max-width: 534px;
    margin-left: 15px;
}
.level-of>div{
    background-color: transparent;
    color: transparent;
    font-size: 0;
}

.cv-button-for-job p,
.picture-name{
   margin-top: 20px;
}
.cv .MuiButtonBase-root{
    margin-top: 0 !important;
}
.knowledge-parent  .MuiFormLabel-root{
    width: 91px;
}
.work-form .MuiTextField-root{
    width: 100%;
}
.table-job-application{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.table-job-application>div{
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 30px;
}
.table-job-application .MuiFormGroup-row>div:last-child{
    width: 74%;
    display: flex;
    justify-content: space-around;
}
.table-job-application tr:not(.first-tr) td{
    width: 100%;
}
.first-tr{
    display: flex;
    justify-content: space-between;
    background-color: transparent !important;
}
.table-job-application tr{
    display:flex;
    justify-content: space-between;
    background:#F8F9FA;
    margin-top: 10px;
}
.table-job-application>tr:last-child{
    margin-bottom: 20px;
}
.programm-languages-level{
    width: 75%;
}
.programm-languages-level>p{
    display: flex;
    justify-content: space-around;
    width: 96%;

}
.table-job-application  .MuiFormGroup-row{
    display: flex;
    justify-content: space-between;
  
}
.answer-of-level-knowledge{
    display: flex;
    justify-content: space-around;
    width: 75%;
}
.send-info-button{
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0  0 20px !important;
}
.languages .MuiFormControl-root {
    
    width: 100% !important;
}
.work-form>div>div {
    margin-bottom: 39px;
}
@media(min-width:387px) and (max-width:465px){
    .table-job-application .MuiFormGroup-row>div:last-child{
        width:68% !important;
    }
    .programm-languages-level {
        font-size: 15px;
    }
}
@media(min-width:320px) and (max-width:768px){
    .work-form,
    .icon-for-job{
        width: 98%;
    }
    .icon-for-job p{
        font-size: 15px;
    }
    .img div {
        width: 100%;
    }
    .icon-for-job img{
       
        margin-bottom: 15px;
    }
   
    .work-form>div{
        width: 100%;
    }
    .knowledge-parent  .MuiFormLabel-root{
        width: 70px;
    }
    .knowledge-parent{
        width: 100%;
    }
    .send-info-button .MuiButtonBase-root{
        margin-left: 20px !important;
    }
}
@media(min-width:769px) and (max-width:1100px){
    .work-form,
    .icon-for-job{
        width: 75%;
    }
}

@media(min-width:1101px) and (max-width:1500px){
    .work-form,
    .icon-for-job{
        width: 55%;
    }
}

@media (min-width:320px) and (max-width:386px){
    .table-job-application .MuiFormGroup-row>div:last-child{
        width: 65% !important;
    }
    .programm-languages-level>p{
        font-size: 11px;
    }
    .table-job-application .MuiFormLabel-root{
        font-size: 13px;
    }
    .table-job-application .MuiFormControlLabel-root{
        display: flex;
    }
    .table-job-application .makeStyles-icon-5{
        width: 14px;
        height: 14px;
    }
    .table-job-application .makeStyles-icon-5::before{
        width: 14px;
        height: 14px;
    }
}