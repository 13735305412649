.information{
    padding: 50px 25px 20px;
}
.cards-course{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 50px auto;
    justify-content: space-evenly;
    align-items: flex-start;
}
.container-card-course {
    width: 340px;
    height: 415px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-bottom: 25px;
    border-radius: 10px;
    overflow: hidden;
}

.overlay {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 2fr 1fr;
    background: rgba(0, 0, 0, .7);
    color: #fef5df;
    opacity: 0;
    transition: all 0.5s;
    font-family: 'Playfair Display', serif;
}

.overlay[data-device-type="mobile"] {
    grid-template-rows: 1fr 2fr 2fr;
}

.items {
    padding-left: 20px;
    letter-spacing: 3px;
}
.price h3{
    margin-bottom: 10px;
}
.price {
    font-size: 18px;
    line-height: 30px;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.7s;
}
.price .old {
    text-decoration: line-through;
    color: #b3b3b3;
}
.cart {
    font-size: 12px;
    opacity: 0;
    letter-spacing: 1px;
    font-family: 'Lato', sans-serif;
    transform: translateY(20px);
    transition: all 0.7s;
}
.price .check-course {
    width: 15px;
    margin-right: 5px;

}
.cart span {
    margin-left: 10px;
}


.container-card-course:hover .overlay {
    opacity: 1;
}

.container-card-course:hover .price {
    transform: translateY(50px);
    transition-delay: 0.3s;
    opacity: 1;
}

.container-card-course:hover .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
}


.container-card-course[data-device-type="mobile"] .overlay {
    opacity: 1;
}

.container-card-course[data-device-type="mobile"] .price {
    transform: translateY(20px);
    transition-delay: 0.3s;
    opacity: 1;
}

.container-card-course[data-device-type="mobile"] .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
}



.buttons button{
    padding: 8px 20px;
    background: transparent;
    color:white;
    font-size: 17px;
    margin-top: 100px;
    border: 1px solid #00FFD5;
    transition: background 0.4s linear;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
   
}

.buttons a{
    text-decoration: none;
}
.buttons button:first-child{  
    margin-bottom: 10px;
    margin-right: 15px;
    display: inline-block;
}

.ul-border{
     border-bottom: 1px solid #00FFD5;
     width: 82%;
     margin-top: 15px;
}

.info-title{
  text-align: center;
}
.motionDiv{
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
  .info-title h4{
    color: #123B85;
    letter-spacing: 0.8px;
    margin: 0 auto 30px;
    font-size: 23px;
    border-left: 2px solid#00ffd6;
    border-right: 2px solid #00ffd6;
    width: 25%;
    height: 28px
  }

.information  .Carousel-button-14 {
    background-color: #d7e6f9 !important;
  
  }
.information .MuiSvgIcon-root{
    color: white !important;
    font-size: 37px !important;
  }
.information .MuiButtonBase-root{
    background-color: transparent;
}
@media(min-width:300px) and (max-width:400px){
    .price{
        font-size: 15px;
    }
    .buttons button{
        display: block;
        margin-top: 0px ;
        font-size: 15px;
    }
    .buttons button:first-child{
   
        margin-top:40px;
        margin-right: 15px;
    }
    .container-card-course:hover  .price {
        transform: translateY(20px);
        transition-delay: 0.3s;
        opacity: 1;
    }
    .info-title h4{
        font-size: 20px;
        width: 300px;
        height: auto;
    }
    .container-card-course {
        height: 380px;
        width: 306px;
    }
    .items{
        letter-spacing: 1px;
    }
    .information{
        padding:40px  0px;
    }
    .cards-course{
        width: 98%;
    }
}

@media(min-width:401px) and (max-width:500px){
    .cards-course{
        width: 97%;
    }

  
}

@media(min-width:400px) and (max-width:768px){
    .info-title h4{
        font-size: 22px;
        width: 320px;
        height: auto;
        margin-top: 50px;
    }
    .buttons button{
        display: block;
        margin-top: 30px ;
    }
    .container-card-course:hover  .price{
        transform: translateY(20px);
    }
    .btn-primary{
        margin-top: 50px;
    }
    .btn-two{
        margin-top: 0 !important;
    }
    .motionDiv{
        width: 25%;
    } 

}
@media(min-width:769px) and (max-width:1750px){
    .cards-course{
        width: 93%;
    }
    .info-title h4{
        font-size: 22px;
        width: 456px;
        height: auto;
        margin-top: 50px;
    }
    .information{
        padding: 10px 15px;
    }
    .motionDiv{
        width: 25%;
    }
}
@media(min-width:1127px) and (max-width:1169px){
    .cards-course{
        width: 97%;
    }
}

@media(min-width:1501px) and (max-width:1574px){
    .information{
        padding: 20px 0px;
    }
}

@media(min-width:1757px) and (max-width:1800px){
    .information{
        padding: 80px 0px 0;
    }
    .info-title h4{
        width: 27%;
    }
}
