
.countdown-for-new-lessons{
    padding: 0px 0 50px; 
	width: 92%;
	color: white;
}
.countdown-for-new-lessons .CarouselItem{
	width: 80%;
	margin: 0 auto;
}

.countdown-for-new-lessons .Carousel-buttonWrapper-8:hover .Carousel-button-10 {
    filter: brightness(120%);
    opacity: 0.4;
    background-color: transparent;
}

.countdown-for-new-lessons .Carousel-button-7 .MuiSvgIcon-root{
	font-size: 40px !important;
}
button[aria-label='Previous'], 
button[aria-label='Next'] {
	background-color: transparent;
    color: #fff;
    opacity: 1;
    border-radius: 5px;
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 50%;
	}
	
button[aria-label='Previous']:hover, 
button[aria-label='Next']:hover{
	background-color: transparent !important;
	}

.hurry-up>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width: 92%;
    margin: 0 auto;
    padding: 30px 0;
}
.hurry-up h2{
	margin-bottom: 20px;
	font-size: 28px;
	color: #00ffd6;
}
.hurry-up h2>span{
	color: white;
}
.border-for-online-lesson{
	border-bottom: 4px solid #ffffff;
	width: 100%;
}

.hurry-up>div:nth-child(2)>div{
    width:47%;
}
.hurry-up>h1{
	text-align: center;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 67%;
    margin: 0 auto 29px;
	padding: 0.2em 0;
	color: #00ffd6;
}
.quickly{
	margin-top: 15px;
}
.quickly1{
	font-size: 17px;
    letter-spacing: 1px;
}
.btns{
	position: relative;
	display: block;
	color: white;
	font-size: 14px;
	text-decoration: none;
	margin: 30px 0;
	padding: 8px 20px;
	text-transform:capitalize;
	overflow: hidden;
	transition: 1s all ease;
  }

  .btns::before{
	background:#078671;
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: -1;
	transition: all 0.6s ease;
  }

  .btn4::before{
	width: 100%;
	height: 0%;
	transform: translate(-50.999999%,-49.99%) rotate(-41deg);
  }
  .btn4:hover::before{
	height: 380%;
  }

.reg-btn{
	width: 28%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.register,
.ssilka{
	padding:10px 30px;
	background-color: transparent;
	border:none;
	font-size: 18px;
	cursor: pointer;
	color: black;
	border:1px solid white;
	border-bottom: 2px solid #00ffd6;
	color: white;
	position: relative;
   z-index: 2;
}

.plan{
	display: flex;
	justify-content: flex-start;
	font-size: 20px;
    align-items: center;
}
.lili{
	font-style: italic;
	display: flex;
}
.lili span{
	margin-right: 11px;
}
.condition{
	 margin-bottom: 10px;
	 display: flex;
	 align-items: center;
	 font-size: 21px;
	 justify-content: flex-start;
	
 }


.condition>div{
	margin-left:29px
}
.plan>div>p,.condition>div>p{
	line-height: 32px;
	
}
.plan>p,.condition>p{
	font-style: italic;
}
.condition>p{
	display: flex;
    justify-content: flex-start;
}

.latest{
	display: flex;
	justify-content: center;
	margin-top: 55px;
	width: 100%;
}
.countdown-text-loading{
	width: 100% !important;
}

@media(min-width:300px) and (max-width:992px){
	.countdown-for-new-lessons{
		width: 100%;
	}
	.hurry-up>div:nth-child(2){
		flex-wrap: wrap;
		width: 100%;
	}
	.hurry-up>div:nth-child(2)>div {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.hurry-up>h1{
		width: 77%;
		font-size: 23px;
		margin: 41px auto 29px;
	}
	.reg-btn{
		flex-wrap: wrap;
		width: 70%;
	}
	.countdown-for-new-lessons .CarouselItem {
		width:96%;
	}
	.condition,
	.plan{
		flex-wrap: wrap;
		font-size: 20px;
		justify-content: center;
		width: 100%;
	}
	.plan{
		width: 100%;
	}
	.lili{
		display: flex;
		flex-wrap: wrap;
		font-size: 20px;
		width: 100%;
		justify-content: center;
		margin:10px 0 20px
	}
    .condition>p{
		margin:15px 0;
		text-align: center;
		width: 100%;
		justify-content: center;

	}
	.countdown-for-new-lessons .Carousel-fullHeightHoverButton-8 {
		top: calc(56% - 20px) !important;
	}
	.quickly1{
		margin-top: 20px;
	}
	.reg-btn button{
		margin: 0;
	}
	.latest{
		margin-top: 19px;
	}
	.register, .ssilka {
		padding: 8px 19px;
	}
	.hurry-up h2{
		text-align: center;
		width: 100%;
	}
  .title-loading{
	  margin: 0 auto;
  }
}


@media(min-width:577px) and (max-width:992px){
	.reg-btn{
		width: 45%;
	}

}


@media(min-width:993px) and (max-width:1500px){
	.hurry-up{
		text-align: center;
	}
	.hurry-up>h1 {
		width: 76%;
		margin: 55px auto 29px;
	}
	.countdown-for-new-lessons .CarouselItem{
		width: 90%;
	}
	.hurry-up>div:nth-child(2){
		flex-wrap: wrap;
	}
	.hurry-up>div:nth-child(2)>div{
		width: 87%;
		display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
	}
	.reg-btn{
		width: 400px;
	}
	.quickly1{

		margin-top: 30px;
	}
	.condition,
	.plan{
		justify-content: center;
		width: 636px;
	
	}
	 .lili,.condition>p{
		width: 100%;
		justify-content: center;
	} 

	
}

@media(min-width:1501px) and (max-width:1920px){
	.reg-btn{
		width: 420px;
	}
	.countdown-for-new-lessons .CarouselItem {
		width: 1430px;
		margin: 0 auto;
	}
	.lili,.condition>p{
		width: 100%;
	} 

}