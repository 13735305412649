
.opinion-parent {
  padding: 88px 0 21px;
}
.opinion-parent .Carousel-button-7,
.opinion-parent .Carousel-button-10{
  background-color: #F8F8F8 !important;
}
.opinion-parent h2 {
  text-align: center;
  border-left: 2px solid#00ffd6;
  border-right: 2px solid #00ffd6;
  padding-bottom: 15px;
  color: #123b85;
  width: 27%;
  height: 33px;
  margin: 0 auto;
}

.student-opinion,
.MuiGrid-container  {
  height: 500px;
}
.student-opinion h3 {
  padding-bottom: 20px;
}

.student {
  width: 50%;
  margin: 80px auto;
  text-align: center;
  border-bottom: 1px solid #123b85;
}

.avatar {
  width: 110px !important;
  height: 110px !important;
  border-radius: 50%;
  text-align: center !important;
  margin: 20px auto !important;
}

.skeleton {
  margin: 0 auto;
}

.opinion {
  font-size: 18px;
  width: 80%;
  margin: 0 auto;
  line-height: 25px;
}
.user-fullname {
  font-size: 25px;
  margin-top: 20px;
}
.student .revies-quotes {
  color: #00ffd6;
  margin:10px auto 5px;
}
.opinion-parent .Carousel-button-14 {
  background-color: #d7e6f9 !important;

}
.opinion-parent .MuiSvgIcon-root{
  color: #143c86 !important;
  font-size: 30px !important;
}

@media (min-width: 300px) and (max-width: 1000px) {
  .opinion {
    width: 100%;
    margin: 0;
    font-size: 18px;
  }
  .student {
    width: 90%;
    margin: 30px auto;
  }
  .user-fullname {
    font-size: 22px;
  }
  .student i {
    margin-top: 7px;
  }
  .student-opinion {
    height: 500px;
  }
  .opinion-parent h2 {
    width: 310px !important;
    height: auto !important;
    font-size: 22px;
    margin:  auto;
  }
}

@media (min-width: 1001px) and (max-width: 1600px) {
  .student-opinion {
    height: 500px;
  }
  .opinion-parent h2{
    width: 500px;
  }
}
@media (min-width: 1601px){
  .opinion-parent h2{
    width: 520px;
  }
}