
.advant{
     width: 100%;
     background-color: #1a304e;
     border-bottom: 0.5px solid #123B94;
     padding: 7px 0;
     display: flex;
     justify-content: center;
     align-items: center;
}
.advantages-parent{
    display: flex;
    justify-content: space-between; 
    text-align: center;
    width: 76%;
    margin:0 auto;
  }
  
.advantages-parent>div{
    width: 18%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding:20px 0 40px ;
}



.advant p{
    line-height: 30px;
    color:white;
    margin-top: 4px;
    font-weight: bold;
    padding:10px 0;
    height: 40px;
    letter-spacing: 0.5px;
}

@media (max-width:567px){
    .advant{
        height: auto;
    
    }
    .advantages-parent{
        width: 100%;
        flex-wrap: wrap;
    }
    .advant img{
        width: 50px;
    }
    .advantages-parent>div{
        width: 100%;
        height: auto;
    }
    .advant p {
        height: auto;
    }
}

@media (min-width:568px) and (max-width:1080px){
    .advant{
        height: auto;
   
    }
    .advantages-parent{
        width: 94%;
        flex-wrap: wrap;
        justify-content: space-around;
    
    }
    .advantages-parent>div{
        width: 43%;
    }
}

@media (min-width:1081px) and (max-width:1700px){
    .advant{
        
        height: 280px;
       
    }
    .advantages-parent>div{
        height: 200px;
        justify-content: space-between;
    }
    .advantages-parent{
        width: 94%;
    }
    .advant p{
       height: auto;
    }
}