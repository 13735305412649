
  .pageError404 {
    height: 100vh;
    background: url("./ab.jpg") no-repeat center/cover;
    background-size: cover;
    overflow: hidden;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
   .text-for-error{
     background-color: rgba(0,0,0,0.5); 
     padding:20px 20px 30px 0;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
   }
  .text-for-error h1 {
    font-size: 13em;
    color: #ccfaff;
    margin-bottom: 10px;
    text-align: center;
    text-shadow: -5px 5px 0px rgb(25 22 22 / 19%), -10px 10px 0px rgb(0 0 0 / 40%), 15px -6px 1px rgb(0 0 0 / 32%);
    font-family: monospace;
    font-weight: bold;
  }

  
  .text-for-error h3 {
    color: #ccfaff;
    margin-left: 30px;
    font-size: 2em;
    text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.7);
    margin-top: 0px;
    font-family: monospace;
    font-weight: bold;
    margin-bottom: 50px;
  }

  .torch {
    margin: -150px 0 0 -150px;
    width: 300px;
    height: 300px;
    box-shadow: 0 0 0 9999em #000000f7;
    opacity: 1;
    border-radius: 50%;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    filter: blur(10px);
    pointer-events:none
  } 
  .torch:after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    box-shadow: inset 0 0 40px 2px #000, 0 0 20px 4px rgba(13, 13, 10, 0.2);
  }