footer{
    background:#01203a;
    width: 100%;
    padding: 90px 0 20px;
}
.footer-parent{
    display:flex;
    justify-content:space-between;
    flex-wrap: wrap;
    width:63%;
    margin: 0 auto;
}
.social{
    text-align: center;
    width: 29%;
}

.socials{
    width: 29%;
}
.socials button{
    display: none;
}
footer iframe{
    margin-bottom: 30px;
    border: none !important;
    box-shadow: 0px 0px 0.8em #5AA7FF;
}
.copyright{
    text-align: center;
    color: white;
    margin-top: 20px; 
    overflow: hidden;
}
.copyright p{
     border-top: 0.5px solid gray;
     padding: 25px 0 0;
}
.vac{
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 17px;
}
.vac li{
    padding: 0px 13px 13px 0;
    font-size: 17px;
    
}
.vac li:last-child{
    border: none;
}
.vac li a:hover{
    color:#5AA7FF;
    cursor: pointer;
}
.vac li a{
    color: white;
    transition: color 0.5s linear;
}
.useful{
    color:white;
    font-size: 20px;
    margin-bottom: 12px;
    text-shadow: 0px 0 0.6em #5AA7FF;
}
.links-slider{
    color:white
}
.links-slider img{
    width: 30px;
    margin-bottom: 12px;
}
.links-slider>p:nth-child(2){
   margin-bottom: 15px;
   font-size: 17px;
   font-style: italic;
   line-height: 32px;
}
.links-slider>p:nth-child(2) a{
    margin-right: 7px;
    color:#00FFD5
}
.links-slider>p:nth-child(3){
    font-weight: bold;
    color:#00FFD5
}
footer .Carousel-root-1{
    width: 304px;
    height: 180px;
    padding:10px 0px;
    font-size: 18px;
    margin-top: 30px;
    border-left: 1px solid gray;
    padding-left: 15px;
    background-color: transparent !important;
}
.useful-links{
    width: 29%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.copy-right{
    width: 15px;
}
footer .Carousel-root-1 button{
    display: none;
}
.rights img {
    margin-right: 5px;
   
}
.link-development-page{
    color: white;

}

@media (min-width:320px) and (max-width:600px){
    .footer-parent{
        width: 83%;
    }
    .useful-links{
        width: 100%;
        display: inline-block;
    }
    .vac li{
        font-size: 17px;
    }
    .social{
        width: 100%;
        margin:30px 0 20px;
        text-align: left;
    }
    .social iframe{
        width: 72%;
        height: 150px;
    }
    .socials{
        width: 100%;
    }
    footer .Carousel-root-1{
        width: 272px;
    }
}

@media (min-width:601px) and (max-width:1000px){
   .footer-parent{
       width: 92%;
       justify-content: space-around;
      
   }
  .footer-parent>div{
      width: 38%;
  }
  footer .Carousel-root-1 {
      width: 100%;
      height: 200px;
  }
  .social iframe{
      width: 100%;
      height: 240px;
      margin-bottom: 0;
  }
  .social{
      height: 220px;
  }
  .usf{
      text-align: center;
      margin-top: 50px;
  }
  .vac{
      text-align: center;
  }
}

@media (min-width:1001px) and (max-width:1520px){
    .footer-parent{
        width: 92%;
        justify-content: space-between;
    }
   .footer-parent>div{
       width: 30%;
   }
   .useful-links{
       width: 30% !important; 
   }
   .social{
       width: 35%;
       margin:0 40px
   }
   .social iframe{
       width: 100% !important;
   }
   footer .Carousel-root-1 {
    width: 100%;
    height: 200px;
}

}

@media (min-width:1270px) and (max-width:1520px){
    .social{
        width: 25% !important;
    }
    .social iframe{
        width: 100% !important;
    }
}

@media (min-width:1521px) and (max-width:1735px){
    .footer-parent{
        width: 82%;
    }
}