.global-style{
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none; 
   user-select: none;
}



.studentPageTitle{
  text-align: center;
  margin: 15px;
  color: #0b3f64;
}
.studentPageText{
  margin: 15px;
  text-align: center;
}

.studentPageErrorText{
  margin: 15px;
  list-style-type: disc;
}

.studentPageErrorContainer{
  color: #F44336;
  margin: auto; 
  max-width: 360px;
  margin-top: 15px;
}
