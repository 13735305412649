
.counter-section-2{
    background:  no-repeat fixed center/cover;
    position: relative;
    background-attachment: fixed;
    background-position: center;
    height: 350px;
    width: 100%;
    z-index: 100;
}
.row5{
    width: 100%;
    height: 100%;
    background: #1A304E;
    opacity: 0.9;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counterParent{
    display: flex;
    justify-content: space-around;
    width: 80%;
}
.counter-box-2{
    color: #FFFFFF;
    text-align: center;
}
.counter-section-2 img{
    width: 70px;
}
.counter-title{
    font-size: 18px;
}
.counterParent h3{
    font-size: 26px;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media(max-width:520px){
    .counter-section-2{
        width: 100%;
        height: 690px;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }
    .counterParent{
        flex-wrap: wrap;
        width: 100%;
        margin: 28px auto;
    }
    .counterParent img{
        width: 40px;
    }
    .counter-title {
        font-size: 16px;
    }
    .counterParent>div{
        width: 100%;
        margin-bottom: 15px;
    }
}
@media(min-width:520px) and (max-width:992px){
    .counter-section-2{
        width: 100%;
        height: 400px;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }
    .counterParent{
        flex-wrap: wrap;
        width: 100%;
        margin: 45px auto 0;
    }
    .counterParent>div{
        width: 30%;
        margin-bottom: 25px;
    }
    .counterParent img{
        width: 50px;
    }
}
@media(min-width:993px) and (max-width:1200px){
    .counter-title{
        width: 70%;
        margin: 0 auto;
    }
    .counterParent>div{
        width: 20%;
    }
}