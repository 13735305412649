
html {
    scroll-behavior: smooth;
}
.to-top {
        background: rgba(10,56,134,0.6);
        position: fixed;
        bottom: 25px;
        right: 98px;
        width: 59px;
        height: 59px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        text-decoration: none;
        opacity: 0;
        pointer-events: none;
        z-index: 200;
        cursor: pointer;
        transform: translateY(-900px);
        transition: 1s;
  }
  .to-top.arrow-active {
    bottom: 25px;
    right: 98px;
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0px);
    transition: 1s;
}
@media (max-width:800px){
  .to-top {
    right: 4px;
  
    }
    .to-top.arrow-active{
      bottom: 93px;
      right: 28px;
    }
}