@import url(https://fonts.googleapis.com/css?family=Nunito:400,700);
.global-style{
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
   user-select: none;
}



.studentPageTitle{
  text-align: center;
  margin: 15px;
  color: #0b3f64;
}
.studentPageText{
  margin: 15px;
  text-align: center;
}

.studentPageErrorText{
  margin: 15px;
  list-style-type: disc;
}

.studentPageErrorContainer{
  color: #F44336;
  margin: auto; 
  max-width: 360px;
  margin-top: 15px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #88ABC2;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.nav-menu {
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 150;
  left: 0px;
  justify-content: center;
  background-color: rgba(66 84 115 / 0.6);
  transition: all 0.1s ease 0s;
}

.logo {
  cursor: pointer;
  width: 19%;
}

.menu-logo-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 65%;
  margin: 0 auto;
}

#smart-menu {
  width: 74%;
}

#nav-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
}

#nav-list a {
  font-size: 17px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 7%;
  transition: color 0.5s linear;
  color: white;
}

#nav-list li img {
  margin-right: 4px;
}

#nav-list li a::after {
  content: "";
  display: flex;
  position: absolute;
  width: 0;
  margin-top: 10px;
  left: 0;
  top: 17px;
  height: 2px;
  background: #00FFD6;
  transition: width 0.3s linear;
}

#nav-list li a:not(.active):hover::after {
  color: #00FFD6;
  width: 100%;
  transition: width 0.5s linear;
  top: 17px;
}

#nav-list li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-social {
  display: none;
}

.social-media {
  display: flex;
  justify-content: space-around;
  height: 364px;
  align-content: flex-end;
  align-items: flex-end;
}

.social-media img {
  width: 26px;
  height: 22px;
}

.social-media>a {
  transition: 0.5s;
}

.social-media>a:hover>img {
  transform: scale(1.1);
}

.checkbtn {
  display: flex;
  font-size: 30px;
  order: 1;
  color: #123B85;
  line-height: 103px;
  width: 41px;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

.nav-menu .active {
  border-bottom: 2px solid #00FFD6;
  padding-bottom: 11px;
}

.close {
  color: white !important;
  font-size: 35px;
  margin-top: 30px;
}

@media (max-width: 952px) {
  .logo {
    width: 225px;
  }
  .nav-menu ul li a {
    font-size: 16px;
  }
}

@media (max-width: 940px) {
  .checkbtn {
    display: block;
  }
  .nav-menu ul>div {
    display: block;
  }
  .nav-menu {
    background-color: rgba(66 84 115 / 0.6);
    height: 80px;
    justify-content: space-around;
    display: flex;
  }
  .hamburger {
    color: white !important;
    margin-top: 35px;
  }
  .nav-menu ul li a {
    font-size: 20px;
    color: white;
  }
  .closed-menu-icon {
    width: 35px;
    height: 30px;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  #smart-menu {
    position: fixed;
    width: 300px;
    height: 100%;
    top: 80px;
    left: 0;
    text-align: center;
    transition: all .5s;
  }
  .closeMenuColor {
    background-color: #052846;
    padding: 50px 0;
    transition: all .5s;
    display: block;
  }
  .openMenuColor {
    background-color: transparent;
    transition: all .5s;
    display: none;
  }
  .openMenu {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #052846;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all .5s;
  }
 
  .menu-logo-icon {
    height: 80px;
    margin: 0;
  }
  .menu-social {
    width: 100%;
    display: block;
    justify-content: space-around;
    align-items: flex-end;
  }
  .menu-social .social-media {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 42px;
    align-items: center;
  }
  .menu-social .social-media a {
    padding-bottom: 0 !important;
  }
  #nav-list {
    height: 83%;
    width: 300px;
    flex-direction: column;
    overflow: auto;
  }
  #nav-list a {
    font-size: 20px;
    padding-bottom: 0;
  }
  #nav-list li {
    width: 100%;
    height: 30px;
  }
}

@media(min-width:941px) and (max-width:1050px) {
  .logo {
    width: 21%;
  }
  .menu-logo-icon {
    width: 86%;
  }
  #nav-list a {
    font-size: 14px;
  }
  #nav-list {
    width: 107%;
  }
  #smart-menu {
    width: 78%;
  }
}

@media(min-width:1051px) and (max-width:1250px) {
  .logo {
    width: 22%;
  }
  .nav-menu ul li a {
    font-size: 15px;
  }
  .menu-logo-icon {
    width: 83%;
  }
  #smart-menu {
    width: 77%;
  }
  #nav-list a {
    font-size: 15px;
  }
}

@media(min-width:1251px) and (max-width:1485px) {
  .logo {
    width: 23%;
  }
  .menu-logo-icon {
    width: 85%;
  }

}

@media screen and (orientation:landscape)  and (min-device-width: 319px) 
and (max-device-width: 940px){
 #smart-menu{
   padding: 20px;
   top: 0;
 }
 #nav-list a{
   font-size: 16px;
 }
 #nav-list{
   height: 93%;
   width: 281px;
 }
}




.social-link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 23.7%;
    margin:15px auto 50px;
}
.social-link a{
 width: 60px;
height: 60px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 100%;
transition: all 0.7s linear;
background-color: #123B85;

}
.social-link a:hover{
    background-color: #5AA7FF;
    transform: scale(1.1);
}

.social-link img{
    width: 100%;
    color: white;
    height: 23px;
    text-align: center;
}

@media (max-width:800px){
    .social-link{
        width: 300px;
        margin: 0 auto 30px;
        justify-content: space-around;
    }
    .social-link a {
        width: 44px;
        height: 44px;
    }
    .social-link a:hover{
        background-color: #5AA7FF;
        transform: scale(1);
    }
    .social-link img{
        height: 18px;
    }
}
@media (min-width:800px) and (max-width:1400px){
    .social-link{
        width: 400px;
    }
    .social-link a {
        width: 55px;
        height: 55px;
    }
}
footer{
    background:#01203a;
    width: 100%;
    padding: 90px 0 20px;
}
.footer-parent{
    display:flex;
    justify-content:space-between;
    flex-wrap: wrap;
    width:63%;
    margin: 0 auto;
}
.social{
    text-align: center;
    width: 29%;
}

.socials{
    width: 29%;
}
.socials button{
    display: none;
}
footer iframe{
    margin-bottom: 30px;
    border: none !important;
    box-shadow: 0px 0px 0.8em #5AA7FF;
}
.copyright{
    text-align: center;
    color: white;
    margin-top: 20px; 
    overflow: hidden;
}
.copyright p{
     border-top: 0.5px solid gray;
     padding: 25px 0 0;
}
.vac{
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 17px;
}
.vac li{
    padding: 0px 13px 13px 0;
    font-size: 17px;
    
}
.vac li:last-child{
    border: none;
}
.vac li a:hover{
    color:#5AA7FF;
    cursor: pointer;
}
.vac li a{
    color: white;
    transition: color 0.5s linear;
}
.useful{
    color:white;
    font-size: 20px;
    margin-bottom: 12px;
    text-shadow: 0px 0 0.6em #5AA7FF;
}
.links-slider{
    color:white
}
.links-slider img{
    width: 30px;
    margin-bottom: 12px;
}
.links-slider>p:nth-child(2){
   margin-bottom: 15px;
   font-size: 17px;
   font-style: italic;
   line-height: 32px;
}
.links-slider>p:nth-child(2) a{
    margin-right: 7px;
    color:#00FFD5
}
.links-slider>p:nth-child(3){
    font-weight: bold;
    color:#00FFD5
}
footer .Carousel-root-1{
    width: 304px;
    height: 180px;
    padding:10px 0px;
    font-size: 18px;
    margin-top: 30px;
    border-left: 1px solid gray;
    padding-left: 15px;
    background-color: transparent !important;
}
.useful-links{
    width: 29%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.copy-right{
    width: 15px;
}
footer .Carousel-root-1 button{
    display: none;
}
.rights img {
    margin-right: 5px;
   
}
.link-development-page{
    color: white;

}

@media (min-width:320px) and (max-width:600px){
    .footer-parent{
        width: 83%;
    }
    .useful-links{
        width: 100%;
        display: inline-block;
    }
    .vac li{
        font-size: 17px;
    }
    .social{
        width: 100%;
        margin:30px 0 20px;
        text-align: left;
    }
    .social iframe{
        width: 72%;
        height: 150px;
    }
    .socials{
        width: 100%;
    }
    footer .Carousel-root-1{
        width: 272px;
    }
}

@media (min-width:601px) and (max-width:1000px){
   .footer-parent{
       width: 92%;
       justify-content: space-around;
      
   }
  .footer-parent>div{
      width: 38%;
  }
  footer .Carousel-root-1 {
      width: 100%;
      height: 200px;
  }
  .social iframe{
      width: 100%;
      height: 240px;
      margin-bottom: 0;
  }
  .social{
      height: 220px;
  }
  .usf{
      text-align: center;
      margin-top: 50px;
  }
  .vac{
      text-align: center;
  }
}

@media (min-width:1001px) and (max-width:1520px){
    .footer-parent{
        width: 92%;
        justify-content: space-between;
    }
   .footer-parent>div{
       width: 30%;
   }
   .useful-links{
       width: 30% !important; 
   }
   .social{
       width: 35%;
       margin:0 40px
   }
   .social iframe{
       width: 100% !important;
   }
   footer .Carousel-root-1 {
    width: 100%;
    height: 200px;
}

}

@media (min-width:1270px) and (max-width:1520px){
    .social{
        width: 25% !important;
    }
    .social iframe{
        width: 100% !important;
    }
}

@media (min-width:1521px) and (max-width:1735px){
    .footer-parent{
        width: 82%;
    }
}
.map{

    line-height: 0; 
}
.map iframe{
    padding-bottom: 0 !important;
}

@media(min-width:300px) and (max-width:1200px){
    .map{
        width: 100%;

    }
    .map iframe{
        height: 350px;
    }
}


@media (min-width:1200px){
    .map iframe{
        height: 450px;
    } 
    .map{
        width: 100%;
    }
}

.get-in-touch{
    display: flex;
    justify-content: space-between;
    background: no-repeat center/cover;
    background-attachment: fixed;
    width: 100%;
    padding:98px 0;
    position: relative;
    height: auto;
}
.get-in-touch::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1A304E;
    opacity: 0.7;
}
.get-in-touch>div{
    width: 50%;
    position: relative;
    z-index: 3;
}
.leftTouch{
    padding-left: 200px; 
}
.leftTouch h1{
    font-size:39px;
    margin-bottom: 25px;
    color: white; 
}
.leftTouch h1 span,.rightTouch h2 span{
    color: #00FFD5;
    
}
.rightTouch h2 span{
    font-size: 23px;
    line-height: 34px;
}

.rightTouch h2{
    color: white;
    padding-left: 76.5px;
    width: 70%;
}
.leftTouch p{
    font-size: 25px;
    color: white;
}

.leftTouch img{
    width: 35px;
}
.leftTouch ul{
    margin-top: 30px;
    text-align: left;
}
.leftTouch ul li{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 33px;
    color: white;
    width: 57%;
    border-bottom: 1px solid #f1f2f3;
    padding: 10px;
}
.text{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 15px;
}
.text span{
    margin-bottom: 7px;
}
.text span>a{
    color: white;
    text-decoration: none;
}
.get-in-touch form{
    width: 80%;
    height:395px;
    padding-left: 56px;
    background-color: transparent;
}
.get-in-touch input{
    width: 250px !important; 
}
.get-in-touch .MuiFormLabel-root{
    font-size: 0.9rem !important;
}
.last{
    width: 554px !important;
}
.btn{
    text-align:left;
    width: 100%;
    display: block;
    padding-left: 24px;
    margin-top: 10px;
}
.get-in-touch .MuiInputBase-root{
    color:white !important
}
.btn button{
    background-color: transparent;
    width: 174px;
    height: 46px;
    font-size: 15px;
    border:2px solid white;
    border-bottom: 2px solid #00FFD5;
    margin-top: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.btn button:hover{
    border: 2px solid #00FFD5;
    border-bottom: 2px solid #00FFD5;
    font-weight: bold;
}
.get-in-touch .MuiInput-underline:before{
    border-bottom: 1px solid #f1f2f3 !important;
}

@media (min-width:300px) and (max-width:992px){
    .get-in-touch{
        flex-wrap: wrap;
        width: 100%;
        padding: 44px 0 60px;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }
    .get-in-touch>div{
        width: 96%;
        display: flex;
        flex-direction: column;
        margin: 48px auto 0;
        justify-content: center;
        align-items: center;
    }
    .rightTouch form>.makeStyles-margin-20{
        margin: 12px !important;
    }
    .get-in-touch form{
        width: 100%;
        margin-top: 4%;
        height: auto;
        padding-left:0px;
        text-align: center;

    }
    .last{
        width: 249px !important;
        display: block;
    }
    .leftTouch{
        padding-left: 15px;
        text-align: center;
    }
    .leftTouch h1{
        font-size: 35px;
    }
    .leftTouch p{
        font-size: 23px;
        padding: 0 20px;
    }
    .leftTouch ul li{
        width: 281px;
    }
    .rightTouch h2{
        padding: 0px 10px;
        text-align: center;
        width: 100%;
        margin-top: 15px;
        font-size: 20px;
    }
    .rightTouch h2 span a{
        font-size: 22px;
    }
    .get-in-touch .MuiInputBase-input {
        color:white !important;   
    }
    .get-in-touch input {
        margin-bottom: 0 !important; 
        display: block;
    }
   
    .get-in-touch .makeStyles-margin-1 {
        margin: 5px !important;
    }
    .btn{
        padding-left: 0;
        text-align: center;
    }
    .get-in-touch .makeStyles-margin-23{
        margin:15px !important

    }
    .btn>.btns{
        display: inline-block;
    }
}

@media (min-width:440px) and (max-width:622px){
    .get-in-touch .MuiInputBase-input {
        color:white !important;
        width: 400px !important;
    }
    .last{
        margin-left: 0 !important;
    }
   
}
@media (min-width:440px) and (max-width:992px){
     .btn{
       
        text-align: center;
    }
}
@media (min-width:622px) and (max-width:992px){
    .get-in-touch .MuiFormControl-root{
        width: 40%;
    }
    .get-in-touch .MuiFormControl-root:nth-child(5){
        width: 85% !important;
    }
    .get-in-touch .MuiFormControl-root:nth-child(5)>div{
        width: 100% !important;
    }
    .leftTouch ul{
        display: flex;
    justify-content: space-around;
    }
}

@media(min-width:993px) and (max-width:1627px){
    .leftTouch{
        padding-left: 70px;
    }
    .leftTouch h1{
        font-size: 37px;
    }
    .rightTouch{
        width: 54%;
    }
    .rightTouch h2{
        width: 99%;
        padding-right: 22px;
    }
    .get-in-touch form {
        height:auto;
        width: 94%;
    }
    .get-in-touch .MuiFormControl-root{
        width: 80%;
    }
    .btn{
        padding-left: 26px;
    }
}

@media(min-width:847px) and (max-width:992px){
    .last{
        margin-top: 5px !important;
    }
}
@media(min-width:480px) and (max-width:581px){
    .last{
        width: 300px !important;
    }
}

.counter-section-2{
    background:  no-repeat fixed center/cover;
    position: relative;
    background-attachment: fixed;
    background-position: center;
    height: 350px;
    width: 100%;
    z-index: 100;
}
.row5{
    width: 100%;
    height: 100%;
    background: #1A304E;
    opacity: 0.9;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counterParent{
    display: flex;
    justify-content: space-around;
    width: 80%;
}
.counter-box-2{
    color: #FFFFFF;
    text-align: center;
}
.counter-section-2 img{
    width: 70px;
}
.counter-title{
    font-size: 18px;
}
.counterParent h3{
    font-size: 26px;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media(max-width:520px){
    .counter-section-2{
        width: 100%;
        height: 690px;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }
    .counterParent{
        flex-wrap: wrap;
        width: 100%;
        margin: 28px auto;
    }
    .counterParent img{
        width: 40px;
    }
    .counter-title {
        font-size: 16px;
    }
    .counterParent>div{
        width: 100%;
        margin-bottom: 15px;
    }
}
@media(min-width:520px) and (max-width:992px){
    .counter-section-2{
        width: 100%;
        height: 400px;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }
    .counterParent{
        flex-wrap: wrap;
        width: 100%;
        margin: 45px auto 0;
    }
    .counterParent>div{
        width: 30%;
        margin-bottom: 25px;
    }
    .counterParent img{
        width: 50px;
    }
}
@media(min-width:993px) and (max-width:1200px){
    .counter-title{
        width: 70%;
        margin: 0 auto;
    }
    .counterParent>div{
        width: 20%;
    }
}

.questions .MuiPaper-root {

    border-bottom: 0.5px solid #00ffd6 !important;
    margin-bottom: 15px !important;
}
.questions .MuiAccordion-root:before{
    background-color: transparent !important;
}
.questions .MuiAccordion-rounded:last-child{
    border-radius: 0 !important;
}
.answer-right{
   margin-left: 30px;
}
.answer-parent{
    display: flex;
    width: 57%;
    margin:40px auto
}
.question-panel-active{
    color: #00ffd6 !important;
    
}
.question-panel-active p{
    font-weight: 600;
    color: #0A3886;
}
.questions{
    width:100%;
    background: white;
    padding:70px 0;
}
.questions h1 span{
    color: #00ffd6;
}
.heading{
    font-size: 15px;
}
.questions h1{
    text-align: center;
    margin: 30px auto;
    font-size: 32px;
    color: #233D63;
    border-left: 2px solid#00ffd6 ;
    border-right: 2px solid #00ffd6;
    width: 20%;
    height: 36px;
}
.questions .MuiPaper-elevation1{
    box-shadow:0px 0px 0px transparent !important;
}
.other-question{
    margin: 0 auto;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    font-style: italic;
}
.answer-parent>div{
    width: 50%;
}
.other-question a{
    color:#123B85;
    cursor: pointer;
    margin-left: 5px;
}
.answer-parent .MuiAccordionSummary-content ,
.answer-parent .MuiAccordionDetails-root{
    width: 100% !important;
    display: inline-block !important;
}
@media (max-width:520px){
    .questions{
        width: 100%;
        margin:0;
        padding: 70px 0 30px;
    }
    .answer-parent{
        width: 100%;
        padding: 0 20px;
        flex-wrap: wrap;
    }
    .answer-right{
        margin-left: 0px;
     }
     .questions h1{
         width: 310px;
        margin: 20px auto 0;
        height: auto;
        font-size: 27px;
    }
    .other-question{
        width: 95%;
        line-height: 25px;
    }
    .answer-parent>div{
        width: 100%;
    }
}


@media(min-width:521px) and (max-width:870px){
    .answer-parent{
        flex-wrap: wrap;
        width: 82% !important;
    }
    .answer-right{
        margin-left: 0px;
     }
     .answer-parent .MuiAccordion-root{
         margin-bottom: 22px !important;
     }
     .answer-parent>div{
        width: 100%;
    }

}
@media(min-width:520px) and (max-width:1200px){
    .questions{
        width: 100%;
        margin:0
    }
    .answer-parent{
        width: 100%;
        padding: 0 10px;
    
    }
    .questions h1{
        width: 400px;
       margin-top: 20px;
   }
}

@media (min-width:1201px) and (max-width:1600px){
    .questions{
        width: 80%;
        margin:0 auto
    }
    .answer-parent{
        width: 100%;
        padding: 0 20px;
    }
    .questions h1{
        width:400px;
       margin-top: 20px;
   }
}

@media (min-width:1601px) and (max-width:1790px){
    .questions h1{
        width: 400px;
       margin-top: 20px;
   }
}
.about-us{
    width: 100%;
    margin-top: 223px;
}
.video-react-control-bar,
.video-react-control-text
{
  display: none;
}

.about-us-parent{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px; 
}

.about-us-parent-left{
    display:flex;
    justify-content: flex-end;
    width: 43%;
  
}
.about-us-parent-left img{
    width: 100%;
    height: 346px;
    box-shadow: 0px 0px 0.8em #123B85;

}
.about-us-parent-left>div{
  width:100% !important;
  height: 100% !important;
  position: relative;
  top:0;
  left: 273px;
  padding-top: 0 !important;
}
.imp{
    position: absolute;
    z-index: 100;
    background: white;
    width: 656px;
    height: 305px;
    left: 49.4%;
    padding: 41px;
}
.imp p{
  line-height: 30px;
  color:#123B84;
  font-style: italic;
}
.imp span{
  color:#0a3886;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
}
.about-us-parent-left>div video{
  width: 100%;
  height: 100%;
}
.fg{
  background:#EBEBEB;
  margin-top: 20px;
  padding:20px 0 20px 10px
}
.fg p{
  display:flex;
  
}
.fg img{
  width: 39px;
  margin-right: 10px;
}
.about-us-parent-left>div::after{
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  width: 100%;
  height: 95%;
  background: #04296b;
  opacity: 0.7;
}

.about-us-parent-right{
  margin-top: 12px;
  padding-top:80px;
  width: 790px;
  height: 333px;
}
.about-us-parent-right>div>p,
.about-us-parent-right>div>div>p{
  font-size: 18px !important;
  color:#123B84
}

.who-we-are{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  flex-wrap: wrap;
}
.who-we-are>div:first-child{
  width: 42%;
}

.who-we-are-more h3{
  color: #123B84;
  font-size: 23px;
  border-left: 2px solid#00ffd6;
  border-right: 2px solid #00ffd6;
  width: 17%;
  height: 26px;
  text-align: center;
  
}

.who-we-are-more>p{
  width: 94%;
  font-size: 20px;
  margin-top: 15px;
  line-height: 36px;
  font-style: italic;
  color: #123B84;
 
}
.certeficate{
  width: 563px;
  height: 405px;
  background:white;
  border:2px solid #103475;
  box-shadow: inset 0px 0px 0.5em #103475;
}
.about-us-text{
  width: 97%;
  padding: 10px 0px;
  margin-top: 15px;
  margin-bottom: 32.5px;
  color:#123B84;
  line-height: 29px

}
.certeficate img{
  width: 100%;
  transform: translate(-36px,-35px);
}
.about-us-text p{
  display: flex;
}
.about-us-text img{
  width: 38px;
  margin-right: 20px;
}
.bordered{
  width: 73%;
  border-top:3px solid #123B84;
  margin-bottom: 40px;
}
.who-we-are-more>div>p{
  font-size: 18px !important;
  font-style: italic;
  color:#123B84;
}
@media(min-width:320px) and (max-width:992px){
  .who-we-are>div:first-child,
  .who-we-are-more>p,
  .who-we-are-more>p,
  .about-us-text
 
  {
    width: 100% !important;
    margin: 20px 0;
  }
 
  .about-us-parent-left{
  width: 94% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  }


  .certeficate {
    width: 92%;
    left: 0;
    padding: 0;
    height: 0%;
    background-color: transparent;
    text-align: center;
    border: none;
    box-shadow: none;
}
 .imp{
  left: 4.5%;
  padding: 10px 0 30px;
  height: auto;
  width: 94%;
 
 }
 .fg{
  background: #EBEBEB;
  margin-top: 20px;
  width: 95%;
  padding: 20px 0 20px 10px;
  margin: 10px auto;
}
 .about-us-parent-left>div{
   left: 1.5%;
 }
 .about-us-parent-right {
   padding-left: 0;
   width: 97%;
   margin: 0 auto;
   padding-top: 0;
  
 }
 .who-we-are-more>p{
   font-size: 16px;
   padding-left: 10px;
 }
 .about-us{
   margin: 0;
 }
 .bordered{
   margin: 15px auto 30px;
   display: none;
 }
 .certeficate img {
  transform: translate(0px,0px);
 }
 .who-we-are-more h3{
   width: 146px;
   height: auto;
 }
 .who-we-are{
  padding: 0 9px;
 }
 .about-us-parent-right>div>p{
   font-size: 14px !important;
   padding: 0 12px;
 }
 .who-we-are-more>div>p{
  font-size: 15px !important;
 }
 .about-us-parent-left>div::after{
  height: 94%;
}
}
@media (min-width:320px) and (max-width:400px){
  .blog-parent > div div a{
    font-size: 16px !important;
    padding: 6px 6px !important; 
  }
  .about-us-parent-right{
    height:418px;
  }
  .about-us-parent-right>div>div>p{
    font-size: 15px !important;
  }
}
@media (min-width:320px) and (max-width:530px){
  .about-us-parent{
    padding-bottom: 60px;
  }
}
@media (min-width:460px) and (max-width:550px){
  .about-us-parent-left>div::after{
    height: 92%;
  }
}
 @media(min-width:769px) and (max-width:992px){
  .about-us-parent-left{
    width: 77% !important;
    margin: 0 auto;
  }
  .about-us-parent-right{
    height: 285px;
  }
  .imp{
    width: 80% !important;
    left: 11.1%;
    height: auto;

  }
  .certeficate {
    width: 65%;
    left: 0;
    padding: 10px 0;
    height: 14%;
}
.certeficate img {
  transform: translate(0px,0px);
}
  .who-we-are-more>p{
    width: 93%;
  }
  .fg{
    background: #EBEBEB;
    margin-top: 20px;
    width: 87%;
    padding: 20px 0 20px 10px;
    margin: 10px auto;
  }
} 


@media(min-width:993px) and (max-width:1400px){

  .who-we-are>div:first-child {
    width: 59%;
    margin-top: 0;
  }
  .about-us-text{
    width: 86%;
    margin-top: 50px;
  }
  .about-us-parent-right{
    width: 40%;
    padding-top: 0;
  }
  .about-us-parent-left>div{
    left: 7.8%;
  }
  .imp{
    width: 475px;
    padding:15px;
    height: 320px;
  }
  .fg{
    background: #EBEBEB;
    margin-top: 20px;
    width: 87%;
    padding: 20px 0 20px 10px;
    margin: 10px auto;
  }
  .who-we-are-more h3{
    padding-left: 10px;
    width: 170px;
    height: auto;
  }
  .about-us {
    margin-top: 142px;
  }
  .who-we-are-more>p {
    font-size: 17px;
  }
  .certeficate {
    width: 39%;
    height: 7%;
  }
  .who-we-are>div:first-child{
    width: 54%;
  }
}


@media(min-width:1401px) and (max-width:1660px){
  .imp{
    margin-top: -57px;

  }
  .who-we-are-more h3{
    padding-left: 10px;
    width: 170px;
    height: auto;
  }
  .about-us-parent-left>div {
    left: 20%;
  }
  .who-we-are-more>p{
    font-size: 19px;
  }
  .certeficate {
    width: 34%;
    height: 7%;
  }
  .who-we-are>div:first-child{
    width: 47%;
  }
  .about-us-text{
    width: 92%;
  }
}

@media(min-width:1627px) and (max-width:1836px){
  .who-we-are-more h3{
    padding-left: 10px;
    width: 170px;
    height: auto;
  }
  .about-us-parent-left>div {
    left: 34%;
  }
 .about-us-text{
    width: 93%;
  }
  .who-we-are-more>p{
    font-size: 18px;
  }
}




.slider {
	position: relative;
	width: 100%;
	height: 85vh;
	overflow: hidden;
	color:black;
}

.slider::after{
	content: "";
	background-color: #1A304E;
	opacity: 0.4;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.slide {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden
}


.slider>div{
	height: 100%;
	width: 100%;
	display: flex;

}
.slider>div>div{
	width: 100%;
}
.slider>div:first-child{
	text-align: center;
	color: white;
    text-align: left;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
	background: rgba(0,0,0,0.2);
}
.slider>div>div:last-child{
	color: white;
	text-align: left;
	display: flex;
    justify-content:flex-start;
	align-items: center;
	width: 63%;
    margin: 0 auto ;
	
}
.slider h1{
	margin-top: 15px;
	font-size: 38px;
	margin-bottom: 10px;
}
.slider p{
	font-size: 20px;
}

.slider polygon{
	fill:white
}
.slider a.nextButton,.slider a.previousButton{
	right: 0 !important;
}

.subtitle{
	font-size: 50px;
	color:#00FFD6;
	border-left: 2px solid #00FFD6;
	padding-left: 15px;

}


@media(max-width:600px){
	.slider {	
		width: 100%;
		height: 65vh;

	}
	.slider>div{
		width: 100%;
		margin: 0;
	}

	.slider>div>div:last-child{
		width: 85%;
		margin-left: 15px;
	}
	.slider svg{
		width: 15px;
	}

	.slider>div{
		height: 100%;
	}
	.slider h1{
		font-size: 27px;
	}
	.slider p{
		font-size: 15px;
		display: inline-block;
	}
	.subtitle{
		font-size: 23px;
	}
	
	.time-wrapper {
		font-size: 17px;
	}


}

@media(min-width:601px) and (max-width:992px){
	

	.subtitle{
		font-size: 30px;
	}
	.slide h1{
		font-size: 42px;
	}

	
	.slider>div>div:last-child {
	    width: 83%;
		color: white;
		text-align: left;
		
	}
}


@media(min-width:992px) and (max-width:1200px){

	.subtitle{
		font-size: 55px;
	}

	.slide>div>div:last-child {

		color: white;
		text-align: left;
	}
}

@media(min-width:1200px) and (max-width:1800px){

	
.slide>div>div:last-child {
    color: white;
    text-align: left;
}
}

.size{
	width: 100%;
    text-align: center;
}
.meet{
    margin-bottom: 50px;
    color: #123B85;
    letter-spacing: 0.6px;
    border-left: 2px solid#00ffd6;
    border-right: 2px solid #00ffd6;
    width: 34%;
    height: 33px;
    margin: 0px auto 20px;
}

.image-area{
    width: 70%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 40px 0;
}
.carder {
    transform: translate(0%,0%);
    width: 23%;
    margin-bottom: 25px;
}

.carder .image {
    width: 100%;
    overflow: hidden;
    height: 99%;
}
.carder .image img {
    width: 100%;
    transition: .5s;
    height: 100%;
}
.carder:hover .image img {
    opacity: .5;

}
.carder .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 82%;
    height: 100%;
    background: #1A304E;
    opacity: 0.9;
    transition: .5s;
    transform-origin: left;
    transform: perspective(2000px) rotateY(-90deg);
}
.carder:hover .details {
    transform: perspective(2000px) rotateY(0deg);
}
.carder .details .center {
    padding: 20px;
    text-align:left; 
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.carder .details .center h1 {
    margin: 0;
    padding: 0;
    color:#01fdd4;
    line-height: 20px;
    font-size: 17px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.role{
    font-style: italic;
    font-size: 17px;
}

.carder .details .center p {
    margin: 10px 0 28px;
    padding: 0;
    
}
.carder .details .center ul {
    margin: 10px auto 0;
    padding: 0;
}
.carder .details .center ul li {
    list-style: none;  
}
.carder .details .center ul li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;

}

.team-border{
     border-top:1px solid #01fdd4;
     width: 50%;  
    padding-bottom: 10px;
}
.like{
    font-size: 13px;
}
.carder .details .center ul li a img{
    width: 20px;
    transition: .5s;
}
.counter span{
    color: #00FED5;
}
.carder p{
    letter-spacing: 1px;
    font-size: 13px;
    margin-top: 8px
}
.carder button{
     padding: 0.6rem;
      outline: none;
      border: none;
      border-radius: 3px;
      background: white;
      color: black;
      font-weight: bold;
      cursor: pointer;
      transition: 0.4s ease
}
.carder .button:hover{
    background: dodgerblue;
    color: white
}


@media (min-width:320px) and (max-width:600px){
    .carder{
        width: 285px;
        height:auto;
       margin-bottom: 46px;
    }
    .carder .image {
        height: 100%;
    }
    .image-area{
        justify-content:center;
        text-align: center;
    
    }
    .carder .details .center h1{
        font-size: 14px;
    }
    .carder p{
        font-size: 12px;
    }
    .role{
        font-size: 13px;
    }
    .size{
        padding: 58px 0;
    }
    .carder .details {
        width: 95%;
        height: 100%;
    }
}

@media (min-width:320px) and (max-width:600px){
    .meet,
    .meet span{
        font-size: 22px;
        width: 310px;
        height: auto;
    }
  
    
}
@media (min-width:601px) and (max-width:1349px){
    .carder{
        width: 298px;
        height: auto;
    }
    .carder .image{
        width: 100%;
        height:100%;
        margin-right: 0;
    }
   .image-area{
       width: 100%;
       justify-content: space-evenly;
   }
   .carder .details .center h1{
    font-size: 14px;
   }
   .carder p{
    font-size: 14px;
  }
  .like{
      font-size: 14.5px;
  }
  .meet{
      height: auto;
      width: 50%;
      margin-bottom: 30px;
  }
  .carder .details{
      width: 87%;
  }
}

@media (min-width:1350px) and (max-width:1599px){
  
    .carder{
        width: 21%;
    height: auto;
    }
    .carder .image{
        width: 100%;
        height: 100%;
        margin-right: 0;

    }
    .image-area{
        width: 100%;
        justify-content: space-evenly;
    }
    .meet{
        margin-bottom: 50px;
    }

}


@media (min-width:1600px) and (max-width:1645px){

    .carder .image{
        width: 100%;

        margin-right: 0;
    }
    .image-area{
        width: 72%;
    }
    .meet{
        margin-bottom: 50px;
    }

}
@media (min-width:1646px) and (max-width:1760px) {
 
    .meet{
        margin-bottom: 50px;
    }
}
@media (min-width:1761px) and (max-width:1900px){
    .meet{
        margin-bottom: 50px;
    }
}
body {
  background-color: #f8f8f8;
}
.blog_background_image{
  background-image: url("/images/img8.jpg");
}
.blog {
  background-color: white;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-bottom: 100px;
  padding-top: 85px;
  position: relative;
  z-index: 2;
  height: 470px;
}
.blog::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #1a304e;
  opacity: 0.9;
  z-index: -1;
}

.blog-parent {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
  width: 80%;
  margin: 67px auto 0;
}
.cardul {
  width: 24%;
  height: 320px;
  border-radius: 15px;
  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  z-index: 5;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}
.blog-loading{
  position: absolute;
  left: 0;
  top: 0;
}
.cardul:hover {
  transform: translateY(20px);
}

.cardul:hover:before {
  opacity: 1;
}

.cardul:hover .info {
  opacity: 1;
  transform: translateY(0px);
}
.cardul:hover h2 {
  background-color: transparent;
  z-index: 3;
  color: white;
}
.cardul:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.cardul img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.cardul .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  margin: -24px auto;
  transition: 0.5s;
}

.cardul .info h1 {
  margin: 0px;
}

.cardul .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}
.cardul h2 {
  position: absolute;
  bottom: 60px;
  background: rgba(255,255,255,0.7);
  width: 100%;
  left: 0;
  height: 59px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 19px;
  color: black;

}
.blog-parent > div div a {
  padding: 8px 8px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 5%;
  text-transform: capitalize;
  border-bottom: 2px solid #00ffd6;
}
.blog-parent > div div a:hover {
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
}
.blog-parent > div div a::before {
  transform: translate(-50.999999%, -49.99%) rotate(-37deg);
}
.blog h5 {
  background-color: rgba(255, 255, 255, 0.1);
  width: 15%;
  margin: 0 auto 10px;
  font-size: 17px;
  border-bottom: 2px solid#00ffd6;
  padding: 0.8em 0.1em;
  border-radius: 3px;
  letter-spacing: 1px;
  color: white;
}
.big-blog>h2 {
  color: white;
  font-size: 35px;
  margin-top: 27px;
}

@media (min-width: 320px) and (max-width: 768px) {
  .cardul {
    width: 320px;
    margin-bottom: 30px;
  }
  .blog h2 {
    font-size: 24px;
    color: black;
  }
  .blog h5 {
    width: 280px;
    color: black;
  }
  .blog-parent > div div a {
    font-size: 18px;
  }
  .cardul h2 {
    font-size: 18px;
    bottom: 75px;
  }
  .blog {
    background-image: none;
    height: auto;
    padding-bottom: 0px;
    background-color: #ebebeb;
  }
  .blog::after {
    display: none;
  }
  .blog-parent {
    margin: 42px auto 0;
  }
}

@media (min-width: 769px) and (max-width: 1020px) {
  .cardul {
    width: 300px;
    margin-bottom: 30px;
  }
  .cardul h2 {
    font-size: 20px;
    bottom: 95px;
  }
  .blog-parent > div div a {
    font-size: 18px;
  }
  .blog h5 {
    width: 280px;
    color: black;
  }
  .blog h2 {
    color: black;
  }
  .blog {
    background-image: none;
    height: auto;
    padding-bottom: 27px;
    background-color: #ebebeb;
  }
  .blog::after {
    display: none;
  }
  .blog-parent {
    margin: 42px auto 0;
  }
}

@media (min-width: 1020px) and (max-width: 1750px) {
  .blog-parent {
    width: 97%;
  }
  .cardul h2 {
    font-size: 20px;
    height: 65px;
    bottom: 85px;
  }
  .blog h5 {
    width: 280px;
  }
  .blog-parent > div div a {
    font-size: 20px;
  }
}

@media (min-width: 1601px) {
  .blog-parent {
    width: 80%;
  }
}


.advant{
     width: 100%;
     background-color: #1a304e;
     border-bottom: 0.5px solid #123B94;
     padding: 7px 0;
     display: flex;
     justify-content: center;
     align-items: center;
}
.advantages-parent{
    display: flex;
    justify-content: space-between; 
    text-align: center;
    width: 76%;
    margin:0 auto;
  }
  
.advantages-parent>div{
    width: 18%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding:20px 0 40px ;
}



.advant p{
    line-height: 30px;
    color:white;
    margin-top: 4px;
    font-weight: bold;
    padding:10px 0;
    height: 40px;
    letter-spacing: 0.5px;
}

@media (max-width:567px){
    .advant{
        height: auto;
    
    }
    .advantages-parent{
        width: 100%;
        flex-wrap: wrap;
    }
    .advant img{
        width: 50px;
    }
    .advantages-parent>div{
        width: 100%;
        height: auto;
    }
    .advant p {
        height: auto;
    }
}

@media (min-width:568px) and (max-width:1080px){
    .advant{
        height: auto;
   
    }
    .advantages-parent{
        width: 94%;
        flex-wrap: wrap;
        justify-content: space-around;
    
    }
    .advantages-parent>div{
        width: 43%;
    }
}

@media (min-width:1081px) and (max-width:1700px){
    .advant{
        
        height: 280px;
       
    }
    .advantages-parent>div{
        height: 200px;
        justify-content: space-between;
    }
    .advantages-parent{
        width: 94%;
    }
    .advant p{
       height: auto;
    }
}

.online-course-modal .MuiInputBase-input{
    width: 350px !important;
    margin-bottom: 10px !important;
}
.online-course-modal .MuiOutlinedInput-multiline textarea{
    height: 120px !important;
}
.modal-online-title h6{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.modal-online-title{
    padding: 16px 13px !important;
}
.modal-online-title h3{
    font-weight: normal;
    color: #123B85;
}

.modal-online-title h6>div{
    width: 45%;
}
.terms-and-conditions p{
    line-height: 25px;
    font-size: 15px;
}
.terms-and-conditions>p:first-child{
    margin-bottom: 40px;
}
.terms-and-conditions>p:first-child span{
    color: #05912F;
}
.terms-and-conditions>p:nth-child(3){
    margin:15px 0 50px
}
.terms-and-conditions h4,
.terms-and-conditions>p:nth-child(4){
    border-left: 1px solid #123B85;
    padding-left: 7px;
}

.terms-and-conditions h4{
    color: #123B85;
}
.border{
    border-right: 3px solid #123B85;
}

.information{
    color: red;
    padding-top: 40px;
    font-style: italic;
}
.info-link{
    font-style: italic;
    font-size: 13px;
    margin-top: 7px;
}
.info-link span {
    cursor: pointer;
    color:#123B85 ;
}
.lesson-type-title{
    font-size: 14px;
}
.price-for-courses{
    border-top: 1px solid #123B85;
    margin-top: 12px;
    padding-top: 7px;
    font-size: 15px;
}
.price-for-courses span{
    color: #123B85;
}
@media(min-width:320px) and (max-width:465px){
    .online-course-modal .MuiInputBase-input{
        width: 200px !important;
        margin-bottom: 10px !important;
    }
}
@media (min-width:320px) and (max-width:870px){
    .terms-and-conditions{
        width: 100% !important;
    }
    .border{
        display: none;
    }
    .modal-online-title h3{
        font-size: 16px;
    }
    .online-course-modal .MuiOutlinedInput-multiline textarea{
        height: 40px !important;
    }
    .terms-and-conditions>p:first-child,
    .terms-and-conditions>p:nth-child(4){
        display: none;
    }
    .online-course-modal .MuiInputBase-input{
        height: 5px !important;
    }
}
.down{
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 86%;
    margin-top: 30px;
    font-size: 18px;
}
.time-wrapper{
    font-weight: bold;
    font-size: 17.5px;
    color: #00ffd6;
}
@media (max-width:992px){
    .timer div:last-child{
        margin-left: 2em;
        margin-right: 2em;
        padding: 3em 1em;
    }
    .down svg{
        width: 0px !important;
        height: 0px !important;
    }

    .down{
        margin-top: 60px;
    }
    .down>div>div{
        width: 0 !important;
        height: 0 !important;
        padding: 0 1em 0 ;  
    }
    .down>div{
        height: 30px !important;
    }
    
}

@media(min-width:320px) and (max-width:600px){
    .down{
        font-size: 17px;
        width: 310px !important;
        margin: 52px auto 0;
    }
    .timer-cyrcle{
        width: 70px !important;
        height: 70px !important;
    }

}
@media(min-width:601px) and (max-width:992px){
    .down{
        font-size: 22px;
        width: 500px !important;
        margin: 52px auto 0;
    }
}

@media(min-width:992px) and (max-width:1800px){
    .down{
        width: 600px;
        margin: 37px auto 0;
    }
    .counterParent{
        width: 97%;
    }
}

.countdown-for-new-lessons{
    padding: 0px 0 50px; 
	width: 92%;
	color: white;
}
.countdown-for-new-lessons .CarouselItem{
	width: 80%;
	margin: 0 auto;
}

.countdown-for-new-lessons .Carousel-buttonWrapper-8:hover .Carousel-button-10 {
    filter: brightness(120%);
    opacity: 0.4;
    background-color: transparent;
}

.countdown-for-new-lessons .Carousel-button-7 .MuiSvgIcon-root{
	font-size: 40px !important;
}
button[aria-label='Previous'], 
button[aria-label='Next'] {
	background-color: transparent;
    color: #fff;
    opacity: 1;
    border-radius: 5px;
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 50%;
	}
	
button[aria-label='Previous']:hover, 
button[aria-label='Next']:hover{
	background-color: transparent !important;
	}

.hurry-up>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width: 92%;
    margin: 0 auto;
    padding: 30px 0;
}
.hurry-up h2{
	margin-bottom: 20px;
	font-size: 28px;
	color: #00ffd6;
}
.hurry-up h2>span{
	color: white;
}
.border-for-online-lesson{
	border-bottom: 4px solid #ffffff;
	width: 100%;
}

.hurry-up>div:nth-child(2)>div{
    width:47%;
}
.hurry-up>h1{
	text-align: center;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 67%;
    margin: 0 auto 29px;
	padding: 0.2em 0;
	color: #00ffd6;
}
.quickly{
	margin-top: 15px;
}
.quickly1{
	font-size: 17px;
    letter-spacing: 1px;
}
.btns{
	position: relative;
	display: block;
	color: white;
	font-size: 14px;
	text-decoration: none;
	margin: 30px 0;
	padding: 8px 20px;
	text-transform:capitalize;
	overflow: hidden;
	transition: 1s all ease;
  }

  .btns::before{
	background:#078671;
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: -1;
	transition: all 0.6s ease;
  }

  .btn4::before{
	width: 100%;
	height: 0%;
	transform: translate(-50.999999%,-49.99%) rotate(-41deg);
  }
  .btn4:hover::before{
	height: 380%;
  }

.reg-btn{
	width: 28%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.register,
.ssilka{
	padding:10px 30px;
	background-color: transparent;
	border:none;
	font-size: 18px;
	cursor: pointer;
	color: black;
	border:1px solid white;
	border-bottom: 2px solid #00ffd6;
	color: white;
	position: relative;
   z-index: 2;
}

.plan{
	display: flex;
	justify-content: flex-start;
	font-size: 20px;
    align-items: center;
}
.lili{
	font-style: italic;
	display: flex;
}
.lili span{
	margin-right: 11px;
}
.condition{
	 margin-bottom: 10px;
	 display: flex;
	 align-items: center;
	 font-size: 21px;
	 justify-content: flex-start;
	
 }


.condition>div{
	margin-left:29px
}
.plan>div>p,.condition>div>p{
	line-height: 32px;
	
}
.plan>p,.condition>p{
	font-style: italic;
}
.condition>p{
	display: flex;
    justify-content: flex-start;
}

.latest{
	display: flex;
	justify-content: center;
	margin-top: 55px;
	width: 100%;
}
.countdown-text-loading{
	width: 100% !important;
}

@media(min-width:300px) and (max-width:992px){
	.countdown-for-new-lessons{
		width: 100%;
	}
	.hurry-up>div:nth-child(2){
		flex-wrap: wrap;
		width: 100%;
	}
	.hurry-up>div:nth-child(2)>div {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.hurry-up>h1{
		width: 77%;
		font-size: 23px;
		margin: 41px auto 29px;
	}
	.reg-btn{
		flex-wrap: wrap;
		width: 70%;
	}
	.countdown-for-new-lessons .CarouselItem {
		width:96%;
	}
	.condition,
	.plan{
		flex-wrap: wrap;
		font-size: 20px;
		justify-content: center;
		width: 100%;
	}
	.plan{
		width: 100%;
	}
	.lili{
		display: flex;
		flex-wrap: wrap;
		font-size: 20px;
		width: 100%;
		justify-content: center;
		margin:10px 0 20px
	}
    .condition>p{
		margin:15px 0;
		text-align: center;
		width: 100%;
		justify-content: center;

	}
	.countdown-for-new-lessons .Carousel-fullHeightHoverButton-8 {
		top: calc(56% - 20px) !important;
	}
	.quickly1{
		margin-top: 20px;
	}
	.reg-btn button{
		margin: 0;
	}
	.latest{
		margin-top: 19px;
	}
	.register, .ssilka {
		padding: 8px 19px;
	}
	.hurry-up h2{
		text-align: center;
		width: 100%;
	}
  .title-loading{
	  margin: 0 auto;
  }
}


@media(min-width:577px) and (max-width:992px){
	.reg-btn{
		width: 45%;
	}

}


@media(min-width:993px) and (max-width:1500px){
	.hurry-up{
		text-align: center;
	}
	.hurry-up>h1 {
		width: 76%;
		margin: 55px auto 29px;
	}
	.countdown-for-new-lessons .CarouselItem{
		width: 90%;
	}
	.hurry-up>div:nth-child(2){
		flex-wrap: wrap;
	}
	.hurry-up>div:nth-child(2)>div{
		width: 87%;
		display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
	}
	.reg-btn{
		width: 400px;
	}
	.quickly1{

		margin-top: 30px;
	}
	.condition,
	.plan{
		justify-content: center;
		width: 636px;
	
	}
	 .lili,.condition>p{
		width: 100%;
		justify-content: center;
	} 

	
}

@media(min-width:1501px) and (max-width:1920px){
	.reg-btn{
		width: 420px;
	}
	.countdown-for-new-lessons .CarouselItem {
		width: 1430px;
		margin: 0 auto;
	}
	.lili,.condition>p{
		width: 100%;
	} 

}

.online{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.online::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: -1;
}
.online-course{
    text-align: center;
    color: white;
    padding: 81px 0 0;
    margin-top: 50px;
}
.online-course h4{
    background-color: rgba(255, 255, 255, 0.1);
    width: 23%;
    margin: 0 auto 10px;
    font-size: 17px;
    padding: 0.8em 0.1em;
    border-radius: 3px;
    letter-spacing: 1px; 
    color: #00ffd6;
}
.online-course h2{
    font-size: 28px;
    width: 69%;
    letter-spacing: 2px;
    margin: 30px auto 30px;
   
}
.online-course button{   
    background-color: rgba(255, 255, 255, 0.1);
    width: 185px;
    margin: 0 auto 10px;
    font-size: 17px;
    cursor: pointer;
    padding: 0.8em 0.1em;
    border-radius: 3px;
    letter-spacing: 1px;
    border: none;
    border-bottom: 2px solid #00ffd6;
    position: relative;
    z-index: 2;
}

@media(min-width:300px) and (max-width:400px){
    .time-wrapper {
        font-size: 16.5px !important;
       
    }
    .online{
        background-attachment: scroll;
    }
}
@media(min-width:300px) and (max-width:992px){
    .online{
      height: auto;
      padding: 30px 0;
      background-attachment: scroll;
    }
  
    .online-course h2{
        width: 95%;
        font-size: 23px;
        margin:40px auto 30px
    }
    .online-course h4{
        width: 280px;
        padding: 0.5em 0;
    }
    .online-course button{
        padding: 10px 20px ;
    }
    .online-course{
        padding-top: 60px;
        margin-top: 5px;
    }
    .time-wrapper {
        font-size: 19.5px;
       
    }
}

@media(min-width:993px) and (max-width:1500px){
    .online{
    height: auto;
} 
   .online-course{
       margin-top: 0;
       padding: 81px 0 71px;
   }
}


/* @media(min-width:769px) and (max-width:860px){
    .online-course{
        padding-top: 60px;
    }
}
@media(min-width:769px) and (max-width:1200px){
    .online{
        height: 438px;
    }
    .online-course h2{
        width: 100%;
        font-size: 40px;
        margin:40px auto 30px
    }
    .online-course h4{
        width: 280px;
        padding: 0.7em 0;
    }
    .online-course button{
        padding: 10px 20px ;
    }

}

@media(min-width:1201px) and (max-width:1660px){
    .online-course h2{
        font-size: 37px;
        margin:40px auto 30px
    }
} */

.opinion-parent {
  padding: 88px 0 21px;
}
.opinion-parent .Carousel-button-7,
.opinion-parent .Carousel-button-10{
  background-color: #F8F8F8 !important;
}
.opinion-parent h2 {
  text-align: center;
  border-left: 2px solid#00ffd6;
  border-right: 2px solid #00ffd6;
  padding-bottom: 15px;
  color: #123b85;
  width: 27%;
  height: 33px;
  margin: 0 auto;
}

.student-opinion,
.MuiGrid-container  {
  height: 500px;
}
.student-opinion h3 {
  padding-bottom: 20px;
}

.student {
  width: 50%;
  margin: 80px auto;
  text-align: center;
  border-bottom: 1px solid #123b85;
}

.avatar {
  width: 110px !important;
  height: 110px !important;
  border-radius: 50%;
  text-align: center !important;
  margin: 20px auto !important;
}

.skeleton {
  margin: 0 auto;
}

.opinion {
  font-size: 18px;
  width: 80%;
  margin: 0 auto;
  line-height: 25px;
}
.user-fullname {
  font-size: 25px;
  margin-top: 20px;
}
.student .revies-quotes {
  color: #00ffd6;
  margin:10px auto 5px;
}
.opinion-parent .Carousel-button-14 {
  background-color: #d7e6f9 !important;

}
.opinion-parent .MuiSvgIcon-root{
  color: #143c86 !important;
  font-size: 30px !important;
}

@media (min-width: 300px) and (max-width: 1000px) {
  .opinion {
    width: 100%;
    margin: 0;
    font-size: 18px;
  }
  .student {
    width: 90%;
    margin: 30px auto;
  }
  .user-fullname {
    font-size: 22px;
  }
  .student i {
    margin-top: 7px;
  }
  .student-opinion {
    height: 500px;
  }
  .opinion-parent h2 {
    width: 310px !important;
    height: auto !important;
    font-size: 22px;
    margin:  auto;
  }
}

@media (min-width: 1001px) and (max-width: 1600px) {
  .student-opinion {
    height: 500px;
  }
  .opinion-parent h2{
    width: 500px;
  }
}
@media (min-width: 1601px){
  .opinion-parent h2{
    width: 520px;
  }
}
.information{
    padding: 50px 25px 20px;
}
.cards-course{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 50px auto;
    justify-content: space-evenly;
    align-items: flex-start;
}
.container-card-course {
    width: 340px;
    height: 415px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-bottom: 25px;
    border-radius: 10px;
    overflow: hidden;
}

.overlay {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 2fr 1fr;
    background: rgba(0, 0, 0, .7);
    color: #fef5df;
    opacity: 0;
    transition: all 0.5s;
    font-family: 'Playfair Display', serif;
}

.overlay[data-device-type="mobile"] {
    grid-template-rows: 1fr 2fr 2fr;
}

.items {
    padding-left: 20px;
    letter-spacing: 3px;
}
.price h3{
    margin-bottom: 10px;
}
.price {
    font-size: 18px;
    line-height: 30px;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.7s;
}
.price .old {
    text-decoration: line-through;
    color: #b3b3b3;
}
.cart {
    font-size: 12px;
    opacity: 0;
    letter-spacing: 1px;
    font-family: 'Lato', sans-serif;
    transform: translateY(20px);
    transition: all 0.7s;
}
.price .check-course {
    width: 15px;
    margin-right: 5px;

}
.cart span {
    margin-left: 10px;
}


.container-card-course:hover .overlay {
    opacity: 1;
}

.container-card-course:hover .price {
    transform: translateY(50px);
    transition-delay: 0.3s;
    opacity: 1;
}

.container-card-course:hover .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
}


.container-card-course[data-device-type="mobile"] .overlay {
    opacity: 1;
}

.container-card-course[data-device-type="mobile"] .price {
    transform: translateY(20px);
    transition-delay: 0.3s;
    opacity: 1;
}

.container-card-course[data-device-type="mobile"] .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
}



.buttons button{
    padding: 8px 20px;
    background: transparent;
    color:white;
    font-size: 17px;
    margin-top: 100px;
    border: 1px solid #00FFD5;
    transition: background 0.4s linear;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
   
}

.buttons a{
    text-decoration: none;
}
.buttons button:first-child{  
    margin-bottom: 10px;
    margin-right: 15px;
    display: inline-block;
}

.ul-border{
     border-bottom: 1px solid #00FFD5;
     width: 82%;
     margin-top: 15px;
}

.info-title{
  text-align: center;
}
.motionDiv{
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
  .info-title h4{
    color: #123B85;
    letter-spacing: 0.8px;
    margin: 0 auto 30px;
    font-size: 23px;
    border-left: 2px solid#00ffd6;
    border-right: 2px solid #00ffd6;
    width: 25%;
    height: 28px
  }

.information  .Carousel-button-14 {
    background-color: #d7e6f9 !important;
  
  }
.information .MuiSvgIcon-root{
    color: white !important;
    font-size: 37px !important;
  }
.information .MuiButtonBase-root{
    background-color: transparent;
}
@media(min-width:300px) and (max-width:400px){
    .price{
        font-size: 15px;
    }
    .buttons button{
        display: block;
        margin-top: 0px ;
        font-size: 15px;
    }
    .buttons button:first-child{
   
        margin-top:40px;
        margin-right: 15px;
    }
    .container-card-course:hover  .price {
        transform: translateY(20px);
        transition-delay: 0.3s;
        opacity: 1;
    }
    .info-title h4{
        font-size: 20px;
        width: 300px;
        height: auto;
    }
    .container-card-course {
        height: 380px;
        width: 306px;
    }
    .items{
        letter-spacing: 1px;
    }
    .information{
        padding:40px  0px;
    }
    .cards-course{
        width: 98%;
    }
}

@media(min-width:401px) and (max-width:500px){
    .cards-course{
        width: 97%;
    }

  
}

@media(min-width:400px) and (max-width:768px){
    .info-title h4{
        font-size: 22px;
        width: 320px;
        height: auto;
        margin-top: 50px;
    }
    .buttons button{
        display: block;
        margin-top: 30px ;
    }
    .container-card-course:hover  .price{
        transform: translateY(20px);
    }
    .btn-primary{
        margin-top: 50px;
    }
    .btn-two{
        margin-top: 0 !important;
    }
    .motionDiv{
        width: 25%;
    } 

}
@media(min-width:769px) and (max-width:1750px){
    .cards-course{
        width: 93%;
    }
    .info-title h4{
        font-size: 22px;
        width: 456px;
        height: auto;
        margin-top: 50px;
    }
    .information{
        padding: 10px 15px;
    }
    .motionDiv{
        width: 25%;
    }
}
@media(min-width:1127px) and (max-width:1169px){
    .cards-course{
        width: 97%;
    }
}

@media(min-width:1501px) and (max-width:1574px){
    .information{
        padding: 20px 0px;
    }
}

@media(min-width:1757px) and (max-width:1800px){
    .information{
        padding: 80px 0px 0;
    }
    .info-title h4{
        width: 27%;
    }
}

.error-message{
  background-color: white;
  height: 100vh;
}
.error-background{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 51%;
  height: 100%;
  margin:0 auto;
  flex-wrap: wrap;
}
.error-background img{
  width: 246px;
}
.error-background>div{
  width: 70%;
  font-family: "Nunito", sans-serif;
}
.error-background h2{
  margin-top: 15px;
  width: 77%;
  line-height: 35px;
}
.error-background span{
  color:#4b84cf
}
.error-background button{
  padding: 10px 28px;
  background-color: #34bdd0;
  border: 1px solid transparent;
  border-bottom: 2px solid #1a2e73;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 18px;
  letter-spacing: 3px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}
.error-background button:last-child{
  padding: 7px 18px;
}
.error-background button:last-child i{
  margin-right: 5px;
}

@media(min-width:320px) and (max-width:576px){
  .error-background img{
    width: 172px;
  }
  .error-background{
    width: 88%;
    margin: 0 auto;
  
  }
  .error-background>div{
    width: 85%;
    margin: 0 auto;
  }
  .error-background h2{
    width: 100%;
    font-size: 17px;
    line-height: 23px;
  }
  .error-background button{
    font-size: 11px;
  }
}

@media(min-width:577px) and (max-width:992px){
  .error-background{
    width: 88%;
    margin: 0 auto;
  
  }
  .error-background>div{
    width: 100%;
  }
}

html {
    scroll-behavior: smooth;
}
.to-top {
        background: rgba(10,56,134,0.6);
        position: fixed;
        bottom: 25px;
        right: 98px;
        width: 59px;
        height: 59px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        text-decoration: none;
        opacity: 0;
        pointer-events: none;
        z-index: 200;
        cursor: pointer;
        transform: translateY(-900px);
        transition: 1s;
  }
  .to-top.arrow-active {
    bottom: 25px;
    right: 98px;
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0px);
    transition: 1s;
}
@media (max-width:800px){
  .to-top {
    right: 4px;
  
    }
    .to-top.arrow-active{
      bottom: 93px;
      right: 28px;
    }
}
.blog-header{
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(111, 120 ,136 ,0.6);
    width: 100%;
    z-index: 1000;
}
.blog-header-parent{
    display:flex;
    align-items: center;
    padding:10px 0;
    width: 61%;
    justify-content: space-between;
    margin: 0 auto;
}
.blog-header img{
    width: 96%;
}
.blog-header button{
    padding:11px 70px;
    background-color: transparent;
    border:1px solid white;
    border-bottom:2px solid #00FFD5;
    color:white;
    font-size: 20px;
    letter-spacing: 2px;
    border-radius: 4px;
    margin: 0;
    cursor: pointer;
}


@media (min-width:320px) and (max-width:768px){
    .blog-header-parent{
        width: 82%;
    }
    .blog-header button{
        padding:5px 30px;
    }
    .blog-header img{
        width: 80%;
    }
}



.more-about-blog-background{
    background-image: url("/images/blog-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;
    height: 600px;
    margin-top: 0px;
    position: relative;
}
.more-about-blog-background::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #233d63;
    opacity: 0.9;
}
.blog-text{
    position: relative;
    z-index:2;
    display:flex;
    flex-direction: column;
    align-items: center;
    top: calc(600px - 64%);
    color:white
}

.ShareButtonBlog{
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px rgba(0,0,0,1); 
    margin-bottom: 5px;
    margin-top: 15px;
}


.blog-text>h3{
    width: auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    color: white;
    padding:8px;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 15px;
}
.blog-text>h2{
    font-size: 35px;
}
.blog-images-and-text{
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    width: 48%;
    margin:40px auto 0;
   
}
.blog-images-and-text>div:first-child{
    width: 41%;
}
.blog-images-and-text>div:last-child{
    width: 47%;
}
.blog-img-parent{
    height: 292px;
    margin-bottom: 10px;
    overflow: hidden;
}
.more-about-blog-top{
     border-bottom:2px solid  ;
     border-image: linear-gradient(to right, #4458dc 17%, #00FFD5 99%) 1;
     margin-bottom: 30px;
     padding-bottom: 20px;
}
.blog-title h2{
    text-shadow: 0 0 0.6em #123C86;
    margin-bottom: 15px;
    font-size: 32px;
}
.blog-info{
    width: 60%;
    margin: 0 auto;
    line-height: 30px;
}
.more-img{
    width: 378px;
}
.more-about-blog-bottom{
    padding-bottom: 20px;
    margin-bottom: 10px;
    padding-top: 20px;
    border-bottom:2px solid;
    border-image: linear-gradient(to right, #4458dc 17%, #00FFD5 99%) 1;
}

.more-about-full{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
    margin:0 auto;
    
}
.more-about-full>div{
    margin-bottom: 30px;
    padding-bottom: 15px;
    border:0.5px solid lightgray;
    text-align: center;
    box-shadow: 0 0 0.5em #123C86;
    height: 427px;
    width: 389px;
    transition: 0.5s;
    
}
.more-info-blog{
    padding-left: 20px;
    text-align: left;
    margin-top: 7px;
}
.more-info-blog h3{
    font-size: 21px;
    margin: 0 0 7px;
    text-transform: capitalize;
    text-shadow: 0 0 0.5em #123C86;
}
.more-info-blog p{
    font-style: italic;
    margin: 4px 0;
}
.more-info-blog a{
    font-size: 18px;
    color:#123C86;
    cursor: pointer;
    transition: color 0.5s linear;
}
.more-info-blog a:hover{
    color: #04d3c3;
}
.more-about-blog-bottom img{
    width: 100%;

}
.more-about-full span{
    display: block;
    margin-bottom: 5px;
}
.arrow-down{
    width: 50px;
}
.view-more{
    display: flex;
    justify-content: center;  
}
.view-more button{
    width: 200px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 50px;
    align-items: center;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    border-radius: 50px;
    transition: all .4s ease-in-out;
}


.view-more button:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
}
.view-more button:focus {
    outline: none;
}
.view-more .eye{
    margin-left: 20px;
    width: 20px;
    height: 20px;
}
.more-about-blog-background svg{
    position: absolute;
    z-index: 3;
    bottom: 0px;
    fill: #f8f8f8;
}


.more-about-full>div:hover{
    box-shadow: 0 0 1.8em #123C86;
}

.blog-img-loading{
    margin-bottom: 0  !important;
}
@media (min-width:320px) and (max-width:1200px){
    .more-about-blog-background{
        height: 555px;
    }

    .more-about-blog-bottom img {
        height: 281px;
        width: 100%;
    }
    .more-about-full>div {
        height: 384px;
        width: 310px;
    }
    .more-info-blog h3{
        font-size: 17px;
    }
    .blog-title h2{
        font-size: 22px;
    }
    .blog-text>h2 {
        font-size: 27px;
        text-align: center;
    }
    .more-img{
        width: 300px;
    }
    .blog-info{
        width: 90%;
    }
    .more-about-full {
        width: 94%;
        justify-content: center;
    }
    .blog-images-and-text{
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        width: 94%;

    }
    .blog-images-and-text>div:first-child,
    .blog-images-and-text>div:last-child{
        width: 100%;
    }
    .blog-images-and-text>div:last-child{
        margin-top: 20px;
    }
    .blog-img-parent{
        height: 275px;
    }
   
}

@media (min-width:660px) and (max-width:1200px){
    .more-about-full{
        justify-content: space-around;
    }
    
}

@media (min-width:1201px) and (max-width:1480px){
    .blog-images-and-text{
        width: 900px;
    }
    .more-about-full {
        width: 96%;
        justify-content: space-around;
    }
    .blog-images-and-text{
        width: 900px;
    }
    .blog-images-and-text>div:first-child{
        width: 41%;
    }
    .blog-images-and-text>div:last-child{
        width: 47%;
    }
}

@media(min-width:1481px){
    .blog-images-and-text{
        width: 900px;
    }
}


.all-information-about-courses{
    background:#022440;

}
.all-information-about-courses .carder{
    width: 280px;
}
.banner{
    background: #f8feff;
    padding:140px 0 40px;
    border-bottom-left-radius: 53px;
    border-bottom-right-radius: 53px;
    width: 90%;
    margin:0px auto ;
}

.all-information-about-courses .carder {
    margin-right: 15px;
}


.ShareButton{
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px rgba(0,0,0,1); 
    margin-bottom: 5px;
}

.ShareButtonText{
     font-size: 20px;
     color: "#123b85"; 
     padding: 4px 
}

.ShareButtonBox{
    justify-content:flex-end !important
}

.course_image{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}
.course_image > button{
    width: 70%;
}
.banner>div{
    display: flex;
    justify-content: space-between;
    width: 76.8%;
    margin: 0 auto;

}
.course-title{
    text-align: left;
    color:white;
    width: 100%;
    
}
.course-title h2{
    font-size: 40px;
    color:#123B85
}
.course-title h2>span{
    color:#00FFD5
}
.course-title div.conditions{
    width: 90%;
    font-size: 18px;
    margin:15px 0 30px;
    color: black;
    line-height: 31px;
}

h4.term_item::before {
    content: '\2713';
    color: #0071bb;
    margin-right: 10px;
}
.term h3{
    width: 90%;
    font-size: 18px;
    margin:15px 0 0;
    color: black;
    line-height: 30px;
}
.term>div{
    width: 48%;
}

.term_item{
    width: 100%;
    margin:10px 0 0;
    color: black;
    line-height: 24px;
}
button.register_course{
    padding:12px 0px;
    font-size: 20px;
    background:transparent;
    border: 1px solid #123b85;
    border-bottom:2px solid #00FFD5;
    color: #123B85;
    letter-spacing: 3px;
    /* font-weight: bold; */
    border-radius: 4px;
    display: block;
    margin: 20px auto;
    cursor: pointer;
}
button.register_course:hover{
    color: white;
    border: 1px solid transparent;
    border-bottom:2px solid transparent;
}
button.register_course.btn4.btns{
    z-index: 2;
}
.general-image{
    height: 402px;
    width: 319px;
    border-radius: 20px;

}
.term{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding:20px 0;
    border-bottom-left-radius: 53px;
    border-bottom: 1px solid white;
    border-bottom-right-radius: 53px;
}
.global{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.type-of-courses{
    border-right: 2px solid #00ffd6;
    margin-right: 15px;
    padding-right: 15px;
}

.global p{
    color: black;
    margin:10px 0 0;
    line-height: 24px; 
    font-size: 17px;
}

.term span:last-child{
    color:#0a3886;
}
.term span{
    font-weight: bold;
}
.more-informacion{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 60%;
    margin:50px auto 0
}
.course-description{
    display: flex;
    flex-direction: column;
}
.course-description h3{
    color: white;
    width: 90%;
    font-size: 30px;
    text-align: center;
}
.languages-to-learn>h3{
   font-size: 22px;
   color:#123b85;
   margin-top: 19px;
}
.icons-for-lang{
    height: 100px;
}
.course-language-name{
    color: black;
    margin-top:5px
}
.image-parent-icon{
    width: 100%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.image-parent-icon div{
    text-align: center;
    color:white;
    padding: 12px 8px;
    border-radius: 3px;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.more-informacion p{
    width: 95%;
    padding:30px 0px 20px;
    line-height: 36px;
    color:white;
    font-size: 19px;
    font-style: italic;
}
.more-informacion img{
    height: 80px;
}

.workers {
    border-top: 1px solid #0a3886;
    margin-top: 50px;
    border-bottom: 1px solid #0a3886;
}

.workers-parent>div:first-child>h3{
    color:#123B85;
    font-size: 22px;
    margin-bottom: 30px;
    margin-top:30px
}
.courses-border{
    border-top: 1px solid #0a3886;
    width: 80%;
}
.workers-parent{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
.tutor{
    height: 310px;
    width: 291px;
    margin-bottom: 45px;
    margin-right: 20px;
}

.notes img{
    width: 30px;
    margin-right: 10px;
}
.notes-parent{
    color:#123B85;
    width: 50%;
}
.notes-parent h3{
    text-align: left;
    font-size: 22px;
    margin-top: 35px;
    margin-bottom: 23px;

}
.notes p{
    font-size: 18px;
    color: black;
    text-align: left;
    line-height: 30px;
}
.notes{
    width: 100%;
}
.notes>div{
    margin:25px auto 25px;
    display:flex;
    flex-direction: column;
    line-height: 45px;
}
.notes>div>div{
    margin-bottom: 9px;
}
.notes div{
    display:flex;
    align-items: flex-start;
}
.tutor-image{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}



@media (min-width:320px) and (max-width:800px){
    .banner{
        padding: 100px 0 60px;
    }
    .course-title h2{
        font-size: 30px;
    }
    .languages-to-learn>h3{
        font-size: 24px;
        margin-top: 7px;
    }
    .header-parent>div:first-child{
        width: 54%;
    }
    .term{
        padding: 0;
        flex-wrap: wrap;
    }
    .banner>div{
         width: 82.8%;
         flex-wrap: wrap;
    }
    .term>div{
        width: 100%;
        margin-bottom: 15px;
    }
    .term>div:last-child{
        width: 100%;
    }
    .general-image{
        height: 293px;
        width: 225px;
    }
    button.register_course{
        font-size: 18px;
    }
    .all-information-about-courses .carder{
        width: 237px;
        height: auto;
    }
    .all-information-about-courses .carder p{
        font-size: 12px;
    }
    .notes-parent ,
    .course-title div.conditions {
        width: 100%;
    }
    .course-title div.conditions {
        font-size: 16px;
    }
 
   
    .all-information-about-courses .details{
        width: 100%;
    }
    .workers-parent>div:first-child>h3,
    .notes-parent h3,
    .course-description h3{
        font-size: 26px;
    }
    .course-description h3{
        width: 95%;
    }
    .notes p{
        font-size: 16px;
    }
    .notes img{
        width: 26px;
    }
    .image-parent-icon{
        margin-top: 10px;
        justify-content: space-around;
    }
    .more-informacion p{
        font-size: 16px;
        width: 100%;
    }
    .more-informacion{
        width: 80%;
    }
}
@media (min-width:801px) and (max-width:1510px){
    .workers-parent{
        justify-content: center;
    }
    .notes-parent{
        width: 69%;
    }
    .tutor-image{
        justify-content: center;
    }
    .tutor-img-loading{
        margin-right: 100px;
        width:320px !important;
        height: 350px !important;
    }
    .notes-loading{
        width: 500px !important;
    }
   
}
@media (min-width:801px) and (max-width:1740px){
    .banner{
        width: 93%;
    }
    .general-image{
        height: 389px;
    width: 300px;
    }
    .banner>div {
        width: 85.8%;
    }
    .term{
        flex-wrap: wrap;
    }
    .term>div,
    .term>div:last-child {
        width: 98%;
    }
    .course_image {
        justify-content: flex-start;
    }
    .languages-to-learn>h3{
        margin-top: 10px;
    }
    .more-informacion{
        width: 80%;
    }
    .more-informacion p,
    .course-description h3{
        width: 100%;
    }

}


@media (min-width:1391px) and (max-width:1740px){
    .term>div{
        width: 43% !important;
    }
    .notes-loading{
        width: 600px !important;
    }

}
@media(min-width:1741px){
    .notes-loading{
        width: 700px !important;
    }

}


.header-parent{
    position: fixed;
    top:0;
    left: 0;
    z-index: 1000;
    background-color: rgba(111, 120 ,136 ,0.6);
    width: 100%;
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:7px 0
}
.header-parent img{
    width: 96%;
}
.header-parent button{
    padding:10px 20px;
    background-color: transparent;
    border:1px solid white;
    border-bottom:2px solid #00FFD5;
    color:white;
    font-size: 20px;
    letter-spacing: 2px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
}


@media(min-width:320px) and (max-width:800px){
    .header-parent img{
        width: 167px;
    }
    .header-parent button{
        padding: 9px 6px;
        font-size: 13px;
    }
}

  .pageError404 {
    height: 100vh;
    background: url(/static/media/ab.df676b72.jpg) no-repeat center/cover;
    background-size: cover;
    overflow: hidden;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
   .text-for-error{
     background-color: rgba(0,0,0,0.5); 
     padding:20px 20px 30px 0;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
   }
  .text-for-error h1 {
    font-size: 13em;
    color: #ccfaff;
    margin-bottom: 10px;
    text-align: center;
    text-shadow: -5px 5px 0px rgb(25 22 22 / 19%), -10px 10px 0px rgb(0 0 0 / 40%), 15px -6px 1px rgb(0 0 0 / 32%);
    font-family: monospace;
    font-weight: bold;
  }

  
  .text-for-error h3 {
    color: #ccfaff;
    margin-left: 30px;
    font-size: 2em;
    text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.7);
    margin-top: 0px;
    font-family: monospace;
    font-weight: bold;
    margin-bottom: 50px;
  }

  .torch {
    margin: -150px 0 0 -150px;
    width: 300px;
    height: 300px;
    box-shadow: 0 0 0 9999em #000000f7;
    opacity: 1;
    border-radius: 50%;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    filter: blur(10px);
    pointer-events:none
  } 
  .torch:after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    box-shadow: inset 0 0 40px 2px #000, 0 0 20px 4px rgba(13, 13, 10, 0.2);
  }


.work-form{
    width: 40%;
    margin: 0 auto;
    background-color: #d4e3ff;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;

}
.work-form .makeStyles-root-1 > *{
    width: 75ch !important;
    margin: 21px auto 20px !important;

}
.work-form .MuiFormGroup-row{
    padding: 10px;
    width: 100%;  

}

.work-form .MuiInput-root{
    width: 100% !important;
}

.work-form .Mui-required {
    margin-bottom: 15px; 
     padding-left: 6px;
}
.work-form .MuiFormLabel-root{
    margin-top: 5px;
    margin-bottom: 12px;
}
.knowledge-parent{
    display: flex;
    justify-content: space-between;
    width: 95.7%;
     background-color:#F8F9FA;
    padding:10px 15px ;
    align-items: center;
}
.radio-buttons{
    display: flex;
    width: 81%;
}
.radio-buttons>div{
    width: 98%;
    display: flex;
    justify-content: space-around;
}
.radio-buttons .MuiFormControlLabel-root{
    margin:0 !important
}

.answer-of-question{
    width: 74%;
    display: flex;
    justify-content: space-around;
}

.level-of-knowledge{
    padding-left: 10px !important;
}
.languages-level{
    display: flex;
    justify-content: space-between;
    background: #F8F9FA;
    padding: 10px 15px;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
}
.fieldests{
    width: 100%;
}
.languages-level .MuiFormControlLabel-root {
    width: 94%;
    display: flex;
    justify-content: space-between;
}
.languages-level .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px !important;
}

.work-form .MuiButton-containedPrimary{
    width: 200px !important;
    height: 40px !important;
    background: transparent;
    border: 2px solid black;
    border-bottom: 2px solid #123B85;
    color: black;
    transition: 0.7s;
}
.work-form .MuiButton-containedPrimary:hover{
    background-color:#123B85 ;
    border:none;
    color: white;
}
.job-application-parent{
    width: 100%;
    height: auto;
    background-color: #F6F6F6;
    padding-top: 20px;
}
.work-form>div{
    width: 77%;
    background-color: white;
    padding:10px 15px 20px;
    border:1px solid lightgray;
    border-radius: 6px;
}
.other-data .MuiFormLabel-root{
    margin-bottom: 12px;
    margin-left: 6px;
}
.work-form .MuiButtonBase-root {
    padding: 0 !important;
    margin-left:5px;
}
.work-form .PrivateSwitchBase-root-6{
    margin-top: 0 !important;
}

.cv .MuiFormLabel-root{
    margin-left: 10px;
}
.gender .MuiFormControlLabel-root,
.marital-status .MuiFormControlLabel-root{
    margin-bottom: 10px;
}
.gender .MuiIconButton-label,
.marital-status .MuiIconButton-label{
    margin-left: 10px;
}
.gender  .MuiTypography-body1,
.marital-status  .MuiTypography-body1{
    margin-left: 6px;
    margin-top: 2px;
}
.icon-for-job
{
    width:43%;
    margin:0 auto;
    padding:20px ;
    background-color: white;
    border-radius: 8px;
    border-bottom: 15px solid #123B85;
   
}
.img{
     display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.img div{
    width: 73%;
}

.icon-for-job img{
    width: 198px;
}
.icon-for-job p{
    width: 100%;
    line-height: 22px;
    
}
.icon-for-job p:last-child{
    margin-top: 15px;
}

.job-border p{
    margin-top: 43px;
    text-align: right;
    font-style: italic;
}
.work-form .MuiFormLabel-asterisk,
.error-for-files{
    color:red !important
}
.cv .MuiButton-contained{
    background-color: transparent !important;
    border:1px solid gray;
    padding:8px 15px !important
}
.cv .MuiButton-contained:hover{
    box-shadow: 0 0 0 transparent !important;
}
.cv .MuiIconButton-root:hover{
    background-color: transparent !important;
}
.level-of{
    display: flex;
    justify-content: space-around;
    max-width: 534px;
    margin-left: 15px;
}
.level-of>div{
    background-color: transparent;
    color: transparent;
    font-size: 0;
}

.cv-button-for-job p,
.picture-name{
   margin-top: 20px;
}
.cv .MuiButtonBase-root{
    margin-top: 0 !important;
}
.knowledge-parent  .MuiFormLabel-root{
    width: 91px;
}
.work-form .MuiTextField-root{
    width: 100%;
}
.table-job-application{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.table-job-application>div{
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 30px;
}
.table-job-application .MuiFormGroup-row>div:last-child{
    width: 74%;
    display: flex;
    justify-content: space-around;
}
.table-job-application tr:not(.first-tr) td{
    width: 100%;
}
.first-tr{
    display: flex;
    justify-content: space-between;
    background-color: transparent !important;
}
.table-job-application tr{
    display:flex;
    justify-content: space-between;
    background:#F8F9FA;
    margin-top: 10px;
}
.table-job-application>tr:last-child{
    margin-bottom: 20px;
}
.programm-languages-level{
    width: 75%;
}
.programm-languages-level>p{
    display: flex;
    justify-content: space-around;
    width: 96%;

}
.table-job-application  .MuiFormGroup-row{
    display: flex;
    justify-content: space-between;
  
}
.answer-of-level-knowledge{
    display: flex;
    justify-content: space-around;
    width: 75%;
}
.send-info-button{
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0  0 20px !important;
}
.languages .MuiFormControl-root {
    
    width: 100% !important;
}
.work-form>div>div {
    margin-bottom: 39px;
}
@media(min-width:387px) and (max-width:465px){
    .table-job-application .MuiFormGroup-row>div:last-child{
        width:68% !important;
    }
    .programm-languages-level {
        font-size: 15px;
    }
}
@media(min-width:320px) and (max-width:768px){
    .work-form,
    .icon-for-job{
        width: 98%;
    }
    .icon-for-job p{
        font-size: 15px;
    }
    .img div {
        width: 100%;
    }
    .icon-for-job img{
       
        margin-bottom: 15px;
    }
   
    .work-form>div{
        width: 100%;
    }
    .knowledge-parent  .MuiFormLabel-root{
        width: 70px;
    }
    .knowledge-parent{
        width: 100%;
    }
    .send-info-button .MuiButtonBase-root{
        margin-left: 20px !important;
    }
}
@media(min-width:769px) and (max-width:1100px){
    .work-form,
    .icon-for-job{
        width: 75%;
    }
}

@media(min-width:1101px) and (max-width:1500px){
    .work-form,
    .icon-for-job{
        width: 55%;
    }
}

@media (min-width:320px) and (max-width:386px){
    .table-job-application .MuiFormGroup-row>div:last-child{
        width: 65% !important;
    }
    .programm-languages-level>p{
        font-size: 11px;
    }
    .table-job-application .MuiFormLabel-root{
        font-size: 13px;
    }
    .table-job-application .MuiFormControlLabel-root{
        display: flex;
    }
    .table-job-application .makeStyles-icon-5{
        width: 14px;
        height: 14px;
    }
    .table-job-application .makeStyles-icon-5::before{
        width: 14px;
        height: 14px;
    }
}
@font-face{
  font-family: ArialArmST;
  font-display: swap;
  src:url(/static/media/ArialArmST.9ad36a89.woff);
}
@font-face{
  font-family: Armguard;
  font-display: swap;
  src:url(/static/media/ARMGUARD.ba9a6d3c.woff);
}
body {
  margin: 0;
  font-family: ArialArmST;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
}
img:not([alt*="Source-Code-logo"]){
  pointer-events: none;
}
*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   outline: none;
   list-style-type: none;                    
}

