
.get-in-touch{
    display: flex;
    justify-content: space-between;
    background: no-repeat center/cover;
    background-attachment: fixed;
    width: 100%;
    padding:98px 0;
    position: relative;
    height: auto;
}
.get-in-touch::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1A304E;
    opacity: 0.7;
}
.get-in-touch>div{
    width: 50%;
    position: relative;
    z-index: 3;
}
.leftTouch{
    padding-left: 200px; 
}
.leftTouch h1{
    font-size:39px;
    margin-bottom: 25px;
    color: white; 
}
.leftTouch h1 span,.rightTouch h2 span{
    color: #00FFD5;
    
}
.rightTouch h2 span{
    font-size: 23px;
    line-height: 34px;
}

.rightTouch h2{
    color: white;
    padding-left: 76.5px;
    width: 70%;
}
.leftTouch p{
    font-size: 25px;
    color: white;
}

.leftTouch img{
    width: 35px;
}
.leftTouch ul{
    margin-top: 30px;
    text-align: left;
}
.leftTouch ul li{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 33px;
    color: white;
    width: 57%;
    border-bottom: 1px solid #f1f2f3;
    padding: 10px;
}
.text{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 15px;
}
.text span{
    margin-bottom: 7px;
}
.text span>a{
    color: white;
    text-decoration: none;
}
.get-in-touch form{
    width: 80%;
    height:395px;
    padding-left: 56px;
    background-color: transparent;
}
.get-in-touch input{
    width: 250px !important; 
}
.get-in-touch .MuiFormLabel-root{
    font-size: 0.9rem !important;
}
.last{
    width: 554px !important;
}
.btn{
    text-align:left;
    width: 100%;
    display: block;
    padding-left: 24px;
    margin-top: 10px;
}
.get-in-touch .MuiInputBase-root{
    color:white !important
}
.btn button{
    background-color: transparent;
    width: 174px;
    height: 46px;
    font-size: 15px;
    border:2px solid white;
    border-bottom: 2px solid #00FFD5;
    margin-top: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.btn button:hover{
    border: 2px solid #00FFD5;
    border-bottom: 2px solid #00FFD5;
    font-weight: bold;
}
.get-in-touch .MuiInput-underline:before{
    border-bottom: 1px solid #f1f2f3 !important;
}

@media (min-width:300px) and (max-width:992px){
    .get-in-touch{
        flex-wrap: wrap;
        width: 100%;
        padding: 44px 0 60px;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }
    .get-in-touch>div{
        width: 96%;
        display: flex;
        flex-direction: column;
        margin: 48px auto 0;
        justify-content: center;
        align-items: center;
    }
    .rightTouch form>.makeStyles-margin-20{
        margin: 12px !important;
    }
    .get-in-touch form{
        width: 100%;
        margin-top: 4%;
        height: auto;
        padding-left:0px;
        text-align: center;

    }
    .last{
        width: 249px !important;
        display: block;
    }
    .leftTouch{
        padding-left: 15px;
        text-align: center;
    }
    .leftTouch h1{
        font-size: 35px;
    }
    .leftTouch p{
        font-size: 23px;
        padding: 0 20px;
    }
    .leftTouch ul li{
        width: 281px;
    }
    .rightTouch h2{
        padding: 0px 10px;
        text-align: center;
        width: 100%;
        margin-top: 15px;
        font-size: 20px;
    }
    .rightTouch h2 span a{
        font-size: 22px;
    }
    .get-in-touch .MuiInputBase-input {
        color:white !important;   
    }
    .get-in-touch input {
        margin-bottom: 0 !important; 
        display: block;
    }
   
    .get-in-touch .makeStyles-margin-1 {
        margin: 5px !important;
    }
    .btn{
        padding-left: 0;
        text-align: center;
    }
    .get-in-touch .makeStyles-margin-23{
        margin:15px !important

    }
    .btn>.btns{
        display: inline-block;
    }
}

@media (min-width:440px) and (max-width:622px){
    .get-in-touch .MuiInputBase-input {
        color:white !important;
        width: 400px !important;
    }
    .last{
        margin-left: 0 !important;
    }
   
}
@media (min-width:440px) and (max-width:992px){
     .btn{
       
        text-align: center;
    }
}
@media (min-width:622px) and (max-width:992px){
    .get-in-touch .MuiFormControl-root{
        width: 40%;
    }
    .get-in-touch .MuiFormControl-root:nth-child(5){
        width: 85% !important;
    }
    .get-in-touch .MuiFormControl-root:nth-child(5)>div{
        width: 100% !important;
    }
    .leftTouch ul{
        display: flex;
    justify-content: space-around;
    }
}

@media(min-width:993px) and (max-width:1627px){
    .leftTouch{
        padding-left: 70px;
    }
    .leftTouch h1{
        font-size: 37px;
    }
    .rightTouch{
        width: 54%;
    }
    .rightTouch h2{
        width: 99%;
        padding-right: 22px;
    }
    .get-in-touch form {
        height:auto;
        width: 94%;
    }
    .get-in-touch .MuiFormControl-root{
        width: 80%;
    }
    .btn{
        padding-left: 26px;
    }
}

@media(min-width:847px) and (max-width:992px){
    .last{
        margin-top: 5px !important;
    }
}
@media(min-width:480px) and (max-width:581px){
    .last{
        width: 300px !important;
    }
}