
.social-link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 23.7%;
    margin:15px auto 50px;
}
.social-link a{
 width: 60px;
height: 60px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 100%;
transition: all 0.7s linear;
background-color: #123B85;

}
.social-link a:hover{
    background-color: #5AA7FF;
    transform: scale(1.1);
}

.social-link img{
    width: 100%;
    color: white;
    height: 23px;
    text-align: center;
}

@media (max-width:800px){
    .social-link{
        width: 300px;
        margin: 0 auto 30px;
        justify-content: space-around;
    }
    .social-link a {
        width: 44px;
        height: 44px;
    }
    .social-link a:hover{
        background-color: #5AA7FF;
        transform: scale(1);
    }
    .social-link img{
        height: 18px;
    }
}
@media (min-width:800px) and (max-width:1400px){
    .social-link{
        width: 400px;
    }
    .social-link a {
        width: 55px;
        height: 55px;
    }
}