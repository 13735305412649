::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #88ABC2;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.nav-menu {
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 150;
  left: 0px;
  justify-content: center;
  background-color: rgba(66 84 115 / 0.6);
  transition: all 0.1s ease 0s;
}

.logo {
  cursor: pointer;
  width: 19%;
}

.menu-logo-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 65%;
  margin: 0 auto;
}

#smart-menu {
  width: 74%;
}

#nav-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
}

#nav-list a {
  font-size: 17px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 7%;
  transition: color 0.5s linear;
  color: white;
}

#nav-list li img {
  margin-right: 4px;
}

#nav-list li a::after {
  content: "";
  display: flex;
  position: absolute;
  width: 0;
  margin-top: 10px;
  left: 0;
  top: 17px;
  height: 2px;
  background: #00FFD6;
  transition: width 0.3s linear;
}

#nav-list li a:not(.active):hover::after {
  color: #00FFD6;
  width: 100%;
  transition: width 0.5s linear;
  top: 17px;
}

#nav-list li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-social {
  display: none;
}

.social-media {
  display: flex;
  justify-content: space-around;
  height: 364px;
  align-content: flex-end;
  align-items: flex-end;
}

.social-media img {
  width: 26px;
  height: 22px;
}

.social-media>a {
  transition: 0.5s;
}

.social-media>a:hover>img {
  transform: scale(1.1);
}

.checkbtn {
  display: flex;
  font-size: 30px;
  order: 1;
  color: #123B85;
  line-height: 103px;
  width: 41px;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

.nav-menu .active {
  border-bottom: 2px solid #00FFD6;
  padding-bottom: 11px;
}

.close {
  color: white !important;
  font-size: 35px;
  margin-top: 30px;
}

@media (max-width: 952px) {
  .logo {
    width: 225px;
  }
  .nav-menu ul li a {
    font-size: 16px;
  }
}

@media (max-width: 940px) {
  .checkbtn {
    display: block;
  }
  .nav-menu ul>div {
    display: block;
  }
  .nav-menu {
    background-color: rgba(66 84 115 / 0.6);
    height: 80px;
    justify-content: space-around;
    display: flex;
  }
  .hamburger {
    color: white !important;
    margin-top: 35px;
  }
  .nav-menu ul li a {
    font-size: 20px;
    color: white;
  }
  .closed-menu-icon {
    width: 35px;
    height: 30px;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  #smart-menu {
    position: fixed;
    width: 300px;
    height: 100%;
    top: 80px;
    left: 0;
    text-align: center;
    transition: all .5s;
  }
  .closeMenuColor {
    background-color: #052846;
    padding: 50px 0;
    transition: all .5s;
    display: block;
  }
  .openMenuColor {
    background-color: transparent;
    transition: all .5s;
    display: none;
  }
  .openMenu {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #052846;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all .5s;
  }
 
  .menu-logo-icon {
    height: 80px;
    margin: 0;
  }
  .menu-social {
    width: 100%;
    display: block;
    justify-content: space-around;
    align-items: flex-end;
  }
  .menu-social .social-media {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 42px;
    align-items: center;
  }
  .menu-social .social-media a {
    padding-bottom: 0 !important;
  }
  #nav-list {
    height: 83%;
    width: 300px;
    flex-direction: column;
    overflow: auto;
  }
  #nav-list a {
    font-size: 20px;
    padding-bottom: 0;
  }
  #nav-list li {
    width: 100%;
    height: 30px;
  }
}

@media(min-width:941px) and (max-width:1050px) {
  .logo {
    width: 21%;
  }
  .menu-logo-icon {
    width: 86%;
  }
  #nav-list a {
    font-size: 14px;
  }
  #nav-list {
    width: 107%;
  }
  #smart-menu {
    width: 78%;
  }
}

@media(min-width:1051px) and (max-width:1250px) {
  .logo {
    width: 22%;
  }
  .nav-menu ul li a {
    font-size: 15px;
  }
  .menu-logo-icon {
    width: 83%;
  }
  #smart-menu {
    width: 77%;
  }
  #nav-list a {
    font-size: 15px;
  }
}

@media(min-width:1251px) and (max-width:1485px) {
  .logo {
    width: 23%;
  }
  .menu-logo-icon {
    width: 85%;
  }

}

@media screen and (orientation:landscape)  and (min-device-width: 319px) 
and (max-device-width: 940px){
 #smart-menu{
   padding: 20px;
   top: 0;
 }
 #nav-list a{
   font-size: 16px;
 }
 #nav-list{
   height: 93%;
   width: 281px;
 }
}


