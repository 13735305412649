@font-face{
  font-family: ArialArmST;
  font-display: swap;
  src:url('./ArialArmST.woff');
}
@font-face{
  font-family: Armguard;
  font-display: swap;
  src:url('./ARMGUARD.woff');
}
body {
  margin: 0;
  font-family: ArialArmST;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
}
img:not([alt*="Source-Code-logo"]){
  pointer-events: none;
}
*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   outline: none;
   list-style-type: none;                    
}
