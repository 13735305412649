.down{
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 86%;
    margin-top: 30px;
    font-size: 18px;
}
.time-wrapper{
    font-weight: bold;
    font-size: 17.5px;
    color: #00ffd6;
}
@media (max-width:992px){
    .timer div:last-child{
        margin-left: 2em;
        margin-right: 2em;
        padding: 3em 1em;
    }
    .down svg{
        width: 0px !important;
        height: 0px !important;
    }

    .down{
        margin-top: 60px;
    }
    .down>div>div{
        width: 0 !important;
        height: 0 !important;
        padding: 0 1em 0 ;  
    }
    .down>div{
        height: 30px !important;
    }
    
}

@media(min-width:320px) and (max-width:600px){
    .down{
        font-size: 17px;
        width: 310px !important;
        margin: 52px auto 0;
    }
    .timer-cyrcle{
        width: 70px !important;
        height: 70px !important;
    }

}
@media(min-width:601px) and (max-width:992px){
    .down{
        font-size: 22px;
        width: 500px !important;
        margin: 52px auto 0;
    }
}

@media(min-width:992px) and (max-width:1800px){
    .down{
        width: 600px;
        margin: 37px auto 0;
    }
    .counterParent{
        width: 97%;
    }
}