
.size{
	width: 100%;
    text-align: center;
}
.meet{
    margin-bottom: 50px;
    color: #123B85;
    letter-spacing: 0.6px;
    border-left: 2px solid#00ffd6;
    border-right: 2px solid #00ffd6;
    width: 34%;
    height: 33px;
    margin: 0px auto 20px;
}

.image-area{
    width: 70%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 40px 0;
}
.carder {
    transform: translate(0%,0%);
    width: 23%;
    margin-bottom: 25px;
}

.carder .image {
    width: 100%;
    overflow: hidden;
    height: 99%;
}
.carder .image img {
    width: 100%;
    transition: .5s;
    height: 100%;
}
.carder:hover .image img {
    opacity: .5;

}
.carder .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 82%;
    height: 100%;
    background: #1A304E;
    opacity: 0.9;
    transition: .5s;
    transform-origin: left;
    transform: perspective(2000px) rotateY(-90deg);
}
.carder:hover .details {
    transform: perspective(2000px) rotateY(0deg);
}
.carder .details .center {
    padding: 20px;
    text-align:left; 
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.carder .details .center h1 {
    margin: 0;
    padding: 0;
    color:#01fdd4;
    line-height: 20px;
    font-size: 17px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.role{
    font-style: italic;
    font-size: 17px;
}

.carder .details .center p {
    margin: 10px 0 28px;
    padding: 0;
    
}
.carder .details .center ul {
    margin: 10px auto 0;
    padding: 0;
}
.carder .details .center ul li {
    list-style: none;  
}
.carder .details .center ul li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;

}

.team-border{
     border-top:1px solid #01fdd4;
     width: 50%;  
    padding-bottom: 10px;
}
.like{
    font-size: 13px;
}
.carder .details .center ul li a img{
    width: 20px;
    transition: .5s;
}
.counter span{
    color: #00FED5;
}
.carder p{
    letter-spacing: 1px;
    font-size: 13px;
    margin-top: 8px
}
.carder button{
     padding: 0.6rem;
      outline: none;
      border: none;
      border-radius: 3px;
      background: white;
      color: black;
      font-weight: bold;
      cursor: pointer;
      transition: 0.4s ease
}
.carder .button:hover{
    background: dodgerblue;
    color: white
}


@media (min-width:320px) and (max-width:600px){
    .carder{
        width: 285px;
        height:auto;
       margin-bottom: 46px;
    }
    .carder .image {
        height: 100%;
    }
    .image-area{
        justify-content:center;
        text-align: center;
    
    }
    .carder .details .center h1{
        font-size: 14px;
    }
    .carder p{
        font-size: 12px;
    }
    .role{
        font-size: 13px;
    }
    .size{
        padding: 58px 0;
    }
    .carder .details {
        width: 95%;
        height: 100%;
    }
}

@media (min-width:320px) and (max-width:600px){
    .meet,
    .meet span{
        font-size: 22px;
        width: 310px;
        height: auto;
    }
  
    
}
@media (min-width:601px) and (max-width:1349px){
    .carder{
        width: 298px;
        height: auto;
    }
    .carder .image{
        width: 100%;
        height:100%;
        margin-right: 0;
    }
   .image-area{
       width: 100%;
       justify-content: space-evenly;
   }
   .carder .details .center h1{
    font-size: 14px;
   }
   .carder p{
    font-size: 14px;
  }
  .like{
      font-size: 14.5px;
  }
  .meet{
      height: auto;
      width: 50%;
      margin-bottom: 30px;
  }
  .carder .details{
      width: 87%;
  }
}

@media (min-width:1350px) and (max-width:1599px){
  
    .carder{
        width: 21%;
    height: auto;
    }
    .carder .image{
        width: 100%;
        height: 100%;
        margin-right: 0;

    }
    .image-area{
        width: 100%;
        justify-content: space-evenly;
    }
    .meet{
        margin-bottom: 50px;
    }

}


@media (min-width:1600px) and (max-width:1645px){

    .carder .image{
        width: 100%;

        margin-right: 0;
    }
    .image-area{
        width: 72%;
    }
    .meet{
        margin-bottom: 50px;
    }

}
@media (min-width:1646px) and (max-width:1760px) {
 
    .meet{
        margin-bottom: 50px;
    }
}
@media (min-width:1761px) and (max-width:1900px){
    .meet{
        margin-bottom: 50px;
    }
}