
.online{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.online::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: -1;
}
.online-course{
    text-align: center;
    color: white;
    padding: 81px 0 0;
    margin-top: 50px;
}
.online-course h4{
    background-color: rgba(255, 255, 255, 0.1);
    width: 23%;
    margin: 0 auto 10px;
    font-size: 17px;
    padding: 0.8em 0.1em;
    border-radius: 3px;
    letter-spacing: 1px; 
    color: #00ffd6;
}
.online-course h2{
    font-size: 28px;
    width: 69%;
    letter-spacing: 2px;
    margin: 30px auto 30px;
   
}
.online-course button{   
    background-color: rgba(255, 255, 255, 0.1);
    width: 185px;
    margin: 0 auto 10px;
    font-size: 17px;
    cursor: pointer;
    padding: 0.8em 0.1em;
    border-radius: 3px;
    letter-spacing: 1px;
    border: none;
    border-bottom: 2px solid #00ffd6;
    position: relative;
    z-index: 2;
}

@media(min-width:300px) and (max-width:400px){
    .time-wrapper {
        font-size: 16.5px !important;
       
    }
    .online{
        background-attachment: scroll;
    }
}
@media(min-width:300px) and (max-width:992px){
    .online{
      height: auto;
      padding: 30px 0;
      background-attachment: scroll;
    }
  
    .online-course h2{
        width: 95%;
        font-size: 23px;
        margin:40px auto 30px
    }
    .online-course h4{
        width: 280px;
        padding: 0.5em 0;
    }
    .online-course button{
        padding: 10px 20px ;
    }
    .online-course{
        padding-top: 60px;
        margin-top: 5px;
    }
    .time-wrapper {
        font-size: 19.5px;
       
    }
}

@media(min-width:993px) and (max-width:1500px){
    .online{
    height: auto;
} 
   .online-course{
       margin-top: 0;
       padding: 81px 0 71px;
   }
}


/* @media(min-width:769px) and (max-width:860px){
    .online-course{
        padding-top: 60px;
    }
}
@media(min-width:769px) and (max-width:1200px){
    .online{
        height: 438px;
    }
    .online-course h2{
        width: 100%;
        font-size: 40px;
        margin:40px auto 30px
    }
    .online-course h4{
        width: 280px;
        padding: 0.7em 0;
    }
    .online-course button{
        padding: 10px 20px ;
    }

}

@media(min-width:1201px) and (max-width:1660px){
    .online-course h2{
        font-size: 37px;
        margin:40px auto 30px
    }
} */