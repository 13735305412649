
.questions .MuiPaper-root {

    border-bottom: 0.5px solid #00ffd6 !important;
    margin-bottom: 15px !important;
}
.questions .MuiAccordion-root:before{
    background-color: transparent !important;
}
.questions .MuiAccordion-rounded:last-child{
    border-radius: 0 !important;
}
.answer-right{
   margin-left: 30px;
}
.answer-parent{
    display: flex;
    width: 57%;
    margin:40px auto
}
.question-panel-active{
    color: #00ffd6 !important;
    
}
.question-panel-active p{
    font-weight: 600;
    color: #0A3886;
}
.questions{
    width:100%;
    background: white;
    padding:70px 0;
}
.questions h1 span{
    color: #00ffd6;
}
.heading{
    font-size: 15px;
}
.questions h1{
    text-align: center;
    margin: 30px auto;
    font-size: 32px;
    color: #233D63;
    border-left: 2px solid#00ffd6 ;
    border-right: 2px solid #00ffd6;
    width: 20%;
    height: 36px;
}
.questions .MuiPaper-elevation1{
    box-shadow:0px 0px 0px transparent !important;
}
.other-question{
    margin: 0 auto;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    font-style: italic;
}
.answer-parent>div{
    width: 50%;
}
.other-question a{
    color:#123B85;
    cursor: pointer;
    margin-left: 5px;
}
.answer-parent .MuiAccordionSummary-content ,
.answer-parent .MuiAccordionDetails-root{
    width: 100% !important;
    display: inline-block !important;
}
@media (max-width:520px){
    .questions{
        width: 100%;
        margin:0;
        padding: 70px 0 30px;
    }
    .answer-parent{
        width: 100%;
        padding: 0 20px;
        flex-wrap: wrap;
    }
    .answer-right{
        margin-left: 0px;
     }
     .questions h1{
         width: 310px;
        margin: 20px auto 0;
        height: auto;
        font-size: 27px;
    }
    .other-question{
        width: 95%;
        line-height: 25px;
    }
    .answer-parent>div{
        width: 100%;
    }
}


@media(min-width:521px) and (max-width:870px){
    .answer-parent{
        flex-wrap: wrap;
        width: 82% !important;
    }
    .answer-right{
        margin-left: 0px;
     }
     .answer-parent .MuiAccordion-root{
         margin-bottom: 22px !important;
     }
     .answer-parent>div{
        width: 100%;
    }

}
@media(min-width:520px) and (max-width:1200px){
    .questions{
        width: 100%;
        margin:0
    }
    .answer-parent{
        width: 100%;
        padding: 0 10px;
    
    }
    .questions h1{
        width: 400px;
       margin-top: 20px;
   }
}

@media (min-width:1201px) and (max-width:1600px){
    .questions{
        width: 80%;
        margin:0 auto
    }
    .answer-parent{
        width: 100%;
        padding: 0 20px;
    }
    .questions h1{
        width:400px;
       margin-top: 20px;
   }
}

@media (min-width:1601px) and (max-width:1790px){
    .questions h1{
        width: 400px;
       margin-top: 20px;
   }
}