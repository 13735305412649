.map{

    line-height: 0; 
}
.map iframe{
    padding-bottom: 0 !important;
}

@media(min-width:300px) and (max-width:1200px){
    .map{
        width: 100%;

    }
    .map iframe{
        height: 350px;
    }
}


@media (min-width:1200px){
    .map iframe{
        height: 450px;
    } 
    .map{
        width: 100%;
    }
}