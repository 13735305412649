@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

.error-message{
  background-color: white;
  height: 100vh;
}
.error-background{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 51%;
  height: 100%;
  margin:0 auto;
  flex-wrap: wrap;
}
.error-background img{
  width: 246px;
}
.error-background>div{
  width: 70%;
  font-family: "Nunito", sans-serif;
}
.error-background h2{
  margin-top: 15px;
  width: 77%;
  line-height: 35px;
}
.error-background span{
  color:#4b84cf
}
.error-background button{
  padding: 10px 28px;
  background-color: #34bdd0;
  border: 1px solid transparent;
  border-bottom: 2px solid #1a2e73;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 18px;
  letter-spacing: 3px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}
.error-background button:last-child{
  padding: 7px 18px;
}
.error-background button:last-child i{
  margin-right: 5px;
}

@media(min-width:320px) and (max-width:576px){
  .error-background img{
    width: 172px;
  }
  .error-background{
    width: 88%;
    margin: 0 auto;
  
  }
  .error-background>div{
    width: 85%;
    margin: 0 auto;
  }
  .error-background h2{
    width: 100%;
    font-size: 17px;
    line-height: 23px;
  }
  .error-background button{
    font-size: 11px;
  }
}

@media(min-width:577px) and (max-width:992px){
  .error-background{
    width: 88%;
    margin: 0 auto;
  
  }
  .error-background>div{
    width: 100%;
  }
}