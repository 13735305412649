
.header-parent{
    position: fixed;
    top:0;
    left: 0;
    z-index: 1000;
    background-color: rgba(111, 120 ,136 ,0.6);
    width: 100%;
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:7px 0
}
.header-parent img{
    width: 96%;
}
.header-parent button{
    padding:10px 20px;
    background-color: transparent;
    border:1px solid white;
    border-bottom:2px solid #00FFD5;
    color:white;
    font-size: 20px;
    letter-spacing: 2px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
}


@media(min-width:320px) and (max-width:800px){
    .header-parent img{
        width: 167px;
    }
    .header-parent button{
        padding: 9px 6px;
        font-size: 13px;
    }
}